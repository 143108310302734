import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-procedures-step",
  templateUrl: "./procedures-step.component.html",
  styleUrls: ["./procedures-step.component.scss"],
})
export class ProceduresStepComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
