import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ThemeService {
  isMobileChanged = new Subject<boolean>();

  animationState = "in";
  displaySideOnMobile = false;
  isMobile = false;

  public toggleMenu() {
    if (this.isMobile) {
      this.displaySideOnMobile = !this.displaySideOnMobile;
    } else {
      this.animationState = this.animationState === "in" ? "out" : "in";
    }
  }

  public closeMenu() {
    if (this.isMobile) {
      this.displaySideOnMobile = false;
    } else {
      this.animationState = "out";
    }
  }

  public openMenu() {
    if (this.isMobile) {
      this.displaySideOnMobile = true;
    } else {
      this.animationState = "in";
    }
  }

  public setMobile(state: boolean) {
    this.isMobile = state;
    this.isMobileChanged.next(false);
  }

  public notifyIsMobileChanged() {
    return this.isMobileChanged.asObservable();
  }
}
