<form [formGroup]="form" (ngSubmit)="save()">
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.allergy" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Allergy" type="text" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.Category" | translate }}:</label>
    </div>
    <div class="p-col">
      <p-dropdown
        [options]="categoryList"
        formControlName="Category"
        optionLabel="name"
        [placeholder]="'medicalRecord.Category' | translate"
        [showClear]="true"
      ></p-dropdown>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.criticality" | translate }}:</label>
    </div>
    <div class="p-col">
      <p-dropdown
        [options]="criticalityList"
        formControlName="Criticality"
        optionLabel="name"
        [placeholder]="'medicalRecord.criticality' | translate"
        [showClear]="true"
      ></p-dropdown>
    </div>
  </div>
  <br />
  <div formArrayName="Notes" class="p-grid">
    <div class="text-icon-row">
      <label>{{ "medicalRecord.notes" | translate }}:</label>
      <div>
        <button
          *ngIf="form?.get('Notes')['controls'].length > 0"
          style="margin-right: 5px; background-color: red"
          pButton
          [icon]="'fas fa-minus'"
          (click)="onRemoveElement('Notes')"
          iconPos="left"
          type="button"
        ></button>
        <button pButton [icon]="'fas fa-plus'" (click)="onAddElement('Notes')" iconPos="left" type="button"></button>
      </div>
    </div>
    <div *ngFor="let notesControl of form?.get('Notes')['controls']; let i = index" class="add-text-field">
      <input pInputText [formControlName]="i" type="text" />
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <!-- <button (click)="onClose()" class="p-button-rounded p-button-danger" icon="fas fa-times"
      [label]="'general.button.texts.Cancel' | translate" type="button" pButton></button> -->
  </div>
</form>
