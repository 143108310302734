<h4 class="card-title mb-3 main-title">{{ "account.AccountInformation" | translate }}</h4>

<h5 class="mb-3 sub-title">{{ "PatientRecord.steps.accountInfo" | translate }}</h5>

<form [formGroup]="form" novalidate (submit)="save()">
  <div class="p-g p-fluid bolded-labels">
    <div class="p-md-3">
      <label>{{ "account.firstname" | translate }} *</label>
      <input type="text" pInputText formControlName="firstname" />

      <br />
      <label>{{ "account.lastname" | translate }} *</label>
      <input type="text" pInputText formControlName="lastname" />

      <br />
      <label>{{ "account.phone" | translate }} *</label>
      <input type="text" pInputText formControlName="phone" />

      <br />
      <label>{{ "account.dateOfBirth" | translate }} *</label>
      <p-calendar
        formControlName="dateOfBirth"
        [monthNavigator]="true"
        [yearNavigator]="true"
        yearRange="1940:2030"
        [showWeek]="false"
        dateFormat="dd/mm/yy"
        [showIcon]="true"
        [maxDate]="maxDateOfBirth"
      >
      </p-calendar>

      <br />
      <label>{{ "account.gender" | translate }}</label>
      <p-dropdown [options]="genders" formControlName="gender" optionLabel="name"></p-dropdown>
    </div>
    <div class="p-md-3">
      <label>{{ "account.address" | translate }}</label>
      <input type="text" pInputText formControlName="address" />

      <br />
      <label>{{ "account.city" | translate }}</label>
      <input type="text" pInputText formControlName="city" />

      <br />
      <label>{{ "account.postcode" | translate }}</label>
      <input type="text" pInputText formControlName="postcode" />

      <br />
      <label>{{ "account.nationality" | translate }}</label>
      <input type="text" pInputText formControlName="nationality" />

      <br />
      <label>{{ "account.educationalLevel" | translate }}</label>
      <p-dropdown [options]="educationalLevels" formControlName="educationalLevel" optionLabel="name"></p-dropdown>
    </div>
    <div class="p-md-3">
      <label>{{ "account.techFamiliarity" | translate }}</label>
      <p-dropdown [options]="techFamiliarity" formControlName="techFamiliarity" optionLabel="name"></p-dropdown>

      <br />
      <label>{{ "account.liveAlone" | translate }}</label>
      <p-dropdown [options]="liveAlone" formControlName="liveAlone" optionLabel="name"></p-dropdown>

      <br />
      <label>{{ "account.children" | translate }}</label>
      <p-dropdown [options]="children" formControlName="children" optionLabel="name"></p-dropdown>

      <br />
      <label>{{ "account.maritalStatus" | translate }}</label>
      <p-dropdown [options]="maritalStatus" formControlName="maritalStatus" optionLabel="name"></p-dropdown>

      <br />
      <label>{{ "account.occupation" | translate }}</label>
      <p-dropdown [options]="occupation" formControlName="occupation" optionLabel="name"></p-dropdown>
    </div>
  </div>

  <div class="p-g p-fluid bolded-labels">
    <div class="p-md-3"></div>
  </div>

  <div class="d-flex align-content-center justify-content-center">
    <button
      [disabled]="isFormSubmitting"
      pButton
      [label]="'general.button.texts.save' | translate"
      class="p-button-rounded p-button-raised p-button-success"
      type="submit"
    ></button>
  </div>
</form>
