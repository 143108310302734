export enum ASSISTANT_ACTIONS {
  printText = "print-text",
  navigate = "navigate",
}

export enum ASSISTANT_URLS {
  profile = "account",
  messages = "chat",
  education = "education",
  calendar = "appointments",
  nutrition = "nutrition",
  goal = "dss",
  notifications = "notifications",
  "decision support system" = "dss",
  questionnaires = "questionnaires",
  patients = "patients",
  "patient-dashboard" = "notifications",
  "mass assign" = "mass-assign",
}

export enum USER_ASSISTANT_URLS {
  profile = "account",
  messages = "chat",
  calendar = "appointments",
  notifications = "notifications",
  patients = "patient-view/dashboard",
  "patient-dashboard" = "notifications",
}
