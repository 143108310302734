import { FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { TranslationModel } from "../core/models/translation.model";
import { Injectable } from "@angular/core";
import { ToastService } from "../core/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import * as fhir from "fhir-stu3";
import * as moment from "moment";

@Injectable()
export class Helpers {
    constructor(private toastService: ToastService, private translateService: TranslateService) { }

    public static markFormGroupTouched(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: any) => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    public static markFormGroupDirty(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: any) => {
            (control as FormGroup).markAsDirty();

            if (control.controls) {
                this.markFormGroupDirty(control);
            }
        });
    }

    public static markFormGroupPristine(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach((control: any) => {
            control.markAsPristine();

            if (control.controls) {
                this.markFormGroupPristine(control);
            }
        });
    }

    public static searchDataArray(searchStr: string, arr: Array<any>): Array<any> {
        const filtered = arr.filter(x => {
            for (let prop in x) {
                if (x[prop] && x[prop].toLowerCase().indexOf(searchStr.toLowerCase()) > -1) return true;
            }
            return false;
        });

        return filtered;
    }

    public static jsonToFormEncoded(data: any) {
        const str = [];
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
            }
        }
        return str.join("&");
    }

    public static getQueryStringParameter(paramName: string) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has(paramName) ? urlParams.get(paramName) : undefined;
    }

    /**
     * @deprecated use getCurrentOrDefaultTranslation.
     */
    public static getCurrentTranslation(data: Array<TranslationModel>) {
        if (!data) return undefined;

        if (data.length === 0) return undefined;

        return data[0];
    }

    public static stripHtmlTags(str: string) {
        return str.replace(/<[^>]*>/g, "");
    }

    public static cutString(str: string, length: number, addDots: boolean = true) {
        const initialLength = str.length;
        str = initialLength > length ? str.substring(0, length - 1) : str;

        if (addDots) {
            str += initialLength > length ? ".." : "";
        }

        return str;
    }

    public static capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public stringifyDate(date: Date) {
        if (!date) return "";

        if (moment(date).isValid()) {
            return moment(date).format(environment.dateDefaultFormat);
        }

        return "";
    }

    public getFhirTranslationFromExtension(extensions: fhir.Extension[]) {
        if (!extensions) return undefined;

        if (extensions.length === 0) return undefined;

        return extensions.find(x => x.url === "translations");
    }

    public getCurrentOrDefaultTranslationFromFhirExtension(resource: fhir.Element) {
        try {
            const extension = this.getFhirTranslationFromExtension(resource.extension!);

            if (!extension) {
                if ((resource as any).display) {
                    return (resource as any).display;
                }

                if ((resource as any).text) {
                    return (resource as any).text;
                }

                return "";
            }

            const translations = JSON.parse(extension.valueString!) as Array<TranslationModel>;

            return this.getCurrentOrDefaultTranslation(translations);
        } catch (error) {
            return "";
        }
    }

    public getCurrentOrDefaultTranslation(data: Array<TranslationModel>) {
        if (!data) return "";

        let lang = this.translateService.currentLang;

        let translation = data.find(x => x.locale === lang);

        if (!translation) {
            translation = data.find(x => x.locale === environment.defaultLang);
        }

        if (!translation && data.length > 0) {
            translation = data[0];
        }

        return translation!.value;
    }

    public responseHasErrors(response: any, showToast: boolean = false) {
        let hasErrors = false;

        if (!response) return hasErrors;

        if (response.status && response.status === 500) {
            hasErrors = true;
            if (showToast) {
                this.toastService.errorMessage(this.translateService.instant(`alerts.${response.statusText}`));
            }
        } else if (response.status && response.status === 400 && response.statusText === "VALIDATION_ERROR"
            && response.message && response.message instanceof Array) {
            hasErrors = true;
            if (showToast) {
                response.message.forEach((message: { message: string, propertyName: string }) => {
                    if (message.message === "NO_CONSENT") {
                        this.toastService.errorMessage(this.translateService.instant(`alerts.${message.message}`));
                    }

                    if (message.message === "REQUIRED_FIELD") {
                        this.toastService.errorMessage(
                            this.translateService.instant(`alerts.${message.message}`)
                            + ". "
                            + this.translateService.instant("Field") + ": " + message.propertyName
                        );
                    }
                });
            }
        }

        return hasErrors;
    }

    public sortByAsc(data: Array<any>, propertyName: string) {
        data.sort((a: any, b: any) => {
            if (a[propertyName] > b[propertyName]) return 1;
            if (a[propertyName] < b[propertyName]) return -1;
            return 0;
        });
    }

    public newGuid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            // tslint:disable-next-line: no-bitwise
            let r = Math.random() * 16 | 0;
            // tslint:disable-next-line: no-bitwise
            let v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public checkArrayEqualNotOrdered(a: Array<any>, b: Array<any>) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        const clonedA = JSON.parse(JSON.stringify(a));
        const clonedB = JSON.parse(JSON.stringify(b));
        clonedA.sort();
        clonedB.sort();

        for (let i = 0; i < clonedA.length; ++i) {
            if (clonedA[i] !== clonedB[i]) return false;
        }
        return true;
    }

    public getPatientName(patient: fhir.Patient) {
        let str = "";

        if (patient.name && patient.name.length > 0) {
            str += patient.name[0].family;
            if (patient.name[0].given && patient.name[0].given.length > 0) {
                str += " " + patient.name[0].given.join(", ");
            }
        }
        return str;
    }

    public getPatientEmail(patient: fhir.Patient) {
        let str = "";

        if (patient.telecom) {
            const emailSearch = patient.telecom.find(x => x.system === "email");
            if (emailSearch) {
                str = emailSearch.value as any;
            }
        }

        return str;
    }

    validateEmail(email: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isFormGroup(control: AbstractControl): control is FormGroup {
        return !!(control as FormGroup).controls;
    }

    public collectErrors(control: AbstractControl): any | null {
        if (this.isFormGroup(control)) {
            return Object.entries(control.controls)
                .reduce(
                    (acc, [key, childControl]) => {
                        const childErrors = this.collectErrors(childControl);
                        if (childErrors) {
                            acc = { ...acc as any, [key]: childErrors };
                        }
                        return acc;
                    },
                    null
                );
        } else {
            return control.errors;
        }
    }

    extractFhirIdFromReference(reference: fhir.Reference) {
        if (!reference.reference) return undefined;

        return reference.reference.split("/")[reference.reference.split("/").length - 1];
    }
}
