<div class="d-flex flex-column">
  <div class="d-flex flex-row header divider-bottom">
    <div class="d-flex flex-row align-content-center">
      <label class="align-self-center mr-2" style="margin-bottom: 0 !important;">
        Start date:
      </label>
      <p-calendar
        [(ngModel)]="filters.fromDate"
        [minDate]="minFilterDate"
        [maxDate]="maxFilterDate"
        dateFormat="dd/mm/yy"
        inputStyleClass="filter-control"
      >
      </p-calendar>
    </div>
    <div class="d-flex flex-row align-content-center flex-grow-1">
      <label class="align-self-center mr-2" style="margin-bottom: 0 !important;">
        End date:
      </label>
      <p-calendar
        [minDate]="minFilterDate"
        [maxDate]="maxFilterDate"
        [(ngModel)]="filters.toDate"
        dateFormat="dd/mm/yy"
        inputStyleClass="filter-control"
      >
      </p-calendar>
    </div>
    <button type="button" class="p-button mr-1" (click)="applyFilters()">
      {{ "general.button.texts.Apply" | translate }}
    </button>
  </div>
  <div class="grid--container" *ngIf="dataSets">
    <div class="grid--container__line">
      <p-chart type="line" [data]="dataSets.lineGraph" [options]="optionsLineChart" [height]="'65vh'" [width]="'100%'"></p-chart>
    </div>
  </div>
</div>
