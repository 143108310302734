export const EDUCATIONAL_LEVELS = ["12 years", "12-14 years", "14 years"];

export const TECH_FAMILIARITY = ["a lot", "moderately", "a little", "not at all"];

export const LIVE_ALONE = ["yes", "no"];

export const CHILDREN = ["yes", "no"];

export const MARITAL_STATUS = ["single", "married", "divorced", "widowed"];

export const OCCUPATION = ["retired", "full-time work", "part-time work", "unemployed", "never employed"];

export const ACTIVE_SMOKER = [
  { id: 0, label: "no" },
  { id: 1, label: "yes" },
];

export const DIABETES = [
  { id: 0, label: "no" },
  { id: 3, label: "yes" },
];

export const COPD = [
  { id: 0, label: "no" },
  { id: 2, label: "yes" },
];

export const HEART_FAILURE = [
  { id: 2, label: "no" },
  { id: 0, label: "yes" },
];

export const BETA_BLOCKER = [
  { id: 3, label: "no" },
  { id: 0, label: "yes" },
];

export const ACE = [
  { id: 1, label: "no" },
  { id: 0, label: "yes" },
];

export const Extensions = {
  caseManager: "case-manager",
  nationality: "http://local-system/nationality",
  educationalLevel: "http://local-system/educationalLevel",
  techFamiliarity: "http://local-system/techFamiliarity",
  liveAlone: "http://local-system/liveAlone",
  children: "http://local-system/children",
  occupation: "http://local-system/occupation",
  maritalStatus: "http://local-system/maritalStatus",

  activeSmoker: "http://local-system/activeSmoker",
  diabetes: "http://local-system/diabetes",
  copd: "http://local-system/copd",
  heartFailure: "http://local-system/heartFailure",
  betaBlocker: "http://local-system/betaBlocker",
  aceArb: "http://local-system/aceArb",
};
