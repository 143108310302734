export const environment = {
  production: true,
  serverPath: "/rest",
  defaultLang: "en",
  dateDefaultFormat: "YYYY-MM-DD",
  sockets: {
    url: "/",
    path: "/ws",
    transports: ["websocket"],
  },
  daysForConsentExpirationNotification: 7,
  demoCredentials: {
    email: "",
    password: "",
  },
  analysisDemo: false,
  agentUrl: "http://vassistant.iti.gr:9165/webhooks/rest/webhook",
  apkUrl: "/assets/mainapp9.apk",
  motionApkUrl: "/assets/motion3.apk",
};
