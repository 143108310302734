import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription, fromEvent, BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: "[appGGDraggable]",
})
export class GGDraggableDirective implements OnInit, OnDestroy {
  private element!: HTMLElement;

  private subscriptions: Subscription[] = [];

  @Output() isMoving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: any) {}

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement as HTMLElement;
    this.initDrag();
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(this.document, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.document, "mousemove").pipe(takeUntil(dragEnd$));

    // 2
    let initialX: number,
      initialY: number,
      currentX = 0,
      currentY = 0;

    let dragSub: Subscription;

    // 3
    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialX = event.clientX - currentX;
      initialY = event.clientY - currentY;
      this.element.classList.add("free-dragging");

      // 4
      dragSub = drag$.subscribe((event: any) => {
        this.isMoving.next(true);
        event.preventDefault();

        currentX = event.clientX - initialX;
        currentY = event.clientY - initialY;

        this.element.style.transform = "translate3d(" + currentX + "px, " + currentY + "px, 0)";
      });
    });

    // 5
    const dragEndSub = dragEnd$.subscribe(() => {
      this.isMoving.next(false);
      initialX = currentX;
      initialY = currentY;
      this.element.classList.remove("free-dragging");
      if (dragSub) {
        dragSub.unsubscribe();
      }
    });

    // 6
    this.subscriptions.push.apply(this.subscriptions, [dragStartSub, dragSub!, dragEndSub]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }
}
