import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { Socket } from "ngx-socket-io";

interface IChatGroup {
  name: string;
  messages: IChatItem[];
  id: string;
  isVisible: boolean;
  groupNewMessageInput?: string;
  groupName?: string;
  hasUnreadMessages?: boolean;
}
interface IChatItem {
  text?: string;
  owner?: "me" | "chatbot";
  displayTime?: string;
  dateTime?: Date;
}

@Component({
  selector: "app-chatbot-ui",
  templateUrl: "./chatbot-ui.component.html",
  styleUrls: ["./chatbot-ui.component.scss"],
})
export class ChatbotUiComponent implements OnInit {
  languageDropdownVisible = false;
  activeChats = 0;
  languages = [
    { id: "en", label: "English" },
    { id: "el", label: "Ελληνικά" },
    { id: "it", label: "Italiano" },
  ];
  chats: IChatGroup[] = [];
  allowScrollDown = true;
  selectedLanguage = "en";
  isAdmin = false;
  containerTransform = "";

  @Input() showChatBot: boolean = false;

  @ViewChild("chatButton", { static: false }) chatButton!: ElementRef<HTMLDivElement>;

  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private socket: Socket
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      this.selectedLanguage = this.translateService.currentLang;
    });
    this.initChatBot();

    this.authService.loginSuccess.subscribe(() => {
      this.initChatBot();
    });
  }

  initChatBot() {
    this.chats = [];
    this.isAdmin = this.authService.getUserRoles()?.includes("ROLE_ADMIN");
    if (this.showChatBot) {
      this.chats.push({
        name: "Heca",
        id: "chatbot",
        isVisible: false,
        groupNewMessageInput: "",
        messages: [],
      });
      this.getMyChatbotConversations();
    }

    this.scrollBottom();
  }

  toggleMessages(id = "chatbot") {
    this.containerTransform = this.chatButton.nativeElement.style.transform;
    if (this.chats.find((z) => z.id === id)) {
      this.chats.find((z) => z.id === id)!.isVisible = !this.chats.find((z) => z.id === id)!.isVisible;
      this.activeChats = this.chats.filter((z) => z.isVisible).length;
    }
    this.scrollBottom();
  }

  chatButtonMoved() {
    if (!this.chatButton) return;
    this.containerTransform = this.chatButton.nativeElement.style.transform;
  }

  sendMessage(id: any = "chatbot") {
    const group = this.chats.find((z) => z.id === id);
    if (!group) return;

    if (!group.groupNewMessageInput) return;

    const messageToSend = group.groupNewMessageInput;
    group.groupNewMessageInput = "";

    this.addMessage({ text: messageToSend, owner: "me", displayTime: moment().format("YYYY-MM-DD HH:mm") }, id);
    this.httpClient
      .post<any>(`${environment.serverPath}/api/chatbot-conversations`, {
        owner: "me",
        message: messageToSend,
      })
      .subscribe((res) => {
        this.httpClient
          .post<any>(`${environment.serverPath}/api/assistant/send`, {
            sender: "Corral",
            message: messageToSend,
            lang: this.translateService.currentLang,
          })
          .subscribe((res) => {
            if (res && res.length > 0) {
              for (const item of res) {
                this.addMessage(
                  {
                    text: item.text,
                    owner: id,
                    displayTime: moment().format("YYYY-MM-DD HH:mm"),
                  },
                  "chatbot"
                );
                this.httpClient
                  .post<any>(`${environment.serverPath}/api/chatbot-conversations`, {
                    owner: "chatbot",
                    message: item.text,
                  })
                  .subscribe();
              }
            }
          });
      });
  }

  toggleLanguageSelector() {
    this.languageDropdownVisible = !this.languageDropdownVisible;
  }

  selectLanguage(langId: string) {
    this.selectedLanguage = langId;
    this.toggleLanguageSelector();
  }

  getMyChatbotConversations() {
    this.httpClient.get<any[]>(`${environment.serverPath}/api/chatbot-conversations`).subscribe((res) => {
      if (!this.isAdmin) {
        for (const item of res) {
          this.chats
            .find((z) => z.id === "chatbot")!
            .messages.push({
              dateTime: new Date(item.createdAt),
              displayTime: moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
              owner: item.owner,
              text: item.message,
            });
        }
      }
    });
  }

  private addMessage(message: IChatItem, id: string) {
    this.chats.find((z) => z.id === id)?.messages?.push(message);
    if (this.allowScrollDown) {
      this.scrollBottom(id);
    }
  }

  private scrollBottom(id?: string) {
    setTimeout(() => {
      try {
        if (!id) {
          const containers = document.querySelectorAll(".messages-container");
          containers.forEach((x) => {
            x.scrollTop = x.scrollHeight;
          });
        } else {
          const container = document.querySelector(`#${id}`) as HTMLDivElement;
          container.scrollTop = container.scrollHeight;
        }
      } catch (err) {}
    }, 20);
  }
}
