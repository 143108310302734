import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { IRecord, ICustomObservation, IObservationCategory } from "./medical-record.model";
import * as fhir from "fhir-stu3";

@Injectable({
  providedIn: "root",
})
export class MedicalRecordService {
  constructor(private httpClient: HttpClient) {}

  public getMedicalRecordCategories() {
    return this.httpClient.get<Array<any>>(`${environment.serverPath}/api/dss/condition-list`).pipe(
      map((x) => {
        const data: { records: Array<IRecord>; canAddObservationRecords: Array<IRecord> } = {
          records: [],
          canAddObservationRecords: [],
        };

        x.forEach((z) => {
          const record: IRecord = { id: z.category, title: `medicalRecord.category.${z.category}`, items: [] };
          const canAddObservationRecord: IRecord = {
            id: z.category,
            title: `medicalRecord.category.${z.category}`,
            items: [],
          };
          z.items
            .filter((c: any) => c.showOnMedicalRecord === true)
            .forEach((c: any) => {
              record.items!.push({
                ...c,
                date: null,
                id: c.code,
                dateStr: "-",
                name: `medicalRecord.observationCode.${c.label}`,
                value: "-",
                min: c.min || c.min === 0 ? c.min : null,
                max: c.max ? c.max : null,
                canAdd: c.canAdd,
              });
            });

          z.items
            .filter((c: any) => c.canAdd === true)
            .forEach((c: any) => {
              canAddObservationRecord.items!.push({
                id: c.code,
                name: `medicalRecord.observationCode.${c.label}`,
                min: c.min ? c.min : null,
                max: c.max ? c.max : null,
              });
            });
          data.records.push(record);
          data.canAddObservationRecords.push(canAddObservationRecord);
        });

        return data;
      })
    );
  }

  public getDssCategories() {
    return this.httpClient.get<Array<any>>(`${environment.serverPath}/api/dss/condition-list`).pipe(
      map((x) => {
        const data: { records: Array<IRecord>; canAddObservationRecords: Array<IRecord> } = {
          records: [],
          canAddObservationRecords: [],
        };

        x.forEach((z) => {
          const record: IRecord = { id: z.category, title: `medicalRecord.category.${z.category}`, items: [] };
          const canAddObservationRecord: IRecord = {
            id: z.category,
            title: `medicalRecord.category.${z.category}`,
            items: [],
          };
          z.items
            .filter((c: any) => c.dss === true)
            .forEach((c: any) => {
              record.items!.push({
                ...c,
                date: null,
                id: c.code,
                dateStr: "-",
                name: `medicalRecord.observationCode.${c.label}`,
                value: "-",
                min: c.min || c.min === 0 ? c.min : null,
                max: c.max ? c.max : null,
                canAdd: c.canAdd,
              });
            });

          z.items
            .filter((c: any) => c.canAdd === true)
            .forEach((c: any) => {
              canAddObservationRecord.items!.push({
                id: c.code,
                name: `medicalRecord.observationCode.${c.label}`,
                min: c.min ? c.min : null,
                max: c.max ? c.max : null,
              });
            });
          data.records.push(record);
          data.canAddObservationRecords.push(canAddObservationRecord);
        });

        return data;
      })
    );
  }

  public addObservation(model: ICustomObservation, patientId: string) {
    const m: fhir.Observation = {
      resourceType: "Observation",
      status: "final",
      code: {
        coding: [
          {
            system: "http://loinc.org",
            code: model.observationCategory,
            display: model.observationCategoryName,
          },
        ],
        text: model.observationCategoryName,
      },
      subject: { reference: `Patient/${patientId}` },
      effectiveDateTime: model.observationDate.toISOString(),
      issued: model.observationDate.toISOString(),
      component: [
        {
          code: {
            coding: [
              {
                system: "http://loinc.org",
                code: model.observationCode.id,
                display: model.observationCode.label,
              },
            ],
            text: model.observationCode.label,
          },
          valueQuantity: {
            value: model.observationValue,
            unit: model.observationUnit,
            system: "http://measureunits.org",
            code: model.observationUnit,
          },
        },
      ],
    };

    return this.httpClient.post(`${environment.serverPath}/api/observations/add-observation/${patientId}`, m);
  }

  public getObservationCategories(patientId: string, category?: string) {
    let searchParams = new HttpParams();
    if (category) searchParams = searchParams.append("category", category);

    return this.httpClient.get<Array<IObservationCategory>>(
      `${environment.serverPath}/api/observation-categories/${patientId}`,
      { params: searchParams }
    );
  }

  public addObservationCategory(patientId: string, models: IObservationCategory[]) {
    return this.httpClient.post<IObservationCategory>(
      `${environment.serverPath}/api/observation-categories/${patientId}`,
      { models: JSON.stringify(models) }
    );
  }

  public deleteObservationCategory(patientId: string, code: string) {
    return this.httpClient.delete<number>(`${environment.serverPath}/api/observation-categories/${patientId}/${code}`);
  }
}
