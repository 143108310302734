import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";

@Component({
  selector: "app-add-family-member",
  templateUrl: "./add-family-member.component.html",
  styleUrls: ["./add-family-member.component.scss"],
})
export class AddFamilyMemberComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  genderList = [
    { id: 0, name: this.translationService.instant("PatientRecord.male"), value: "male" },
    { id: 1, name: this.translationService.instant("PatientRecord.female"), value: "female" },
  ];

  boolList = [
    { id: 0, name: this.translationService.instant("PatientRecord.false"), value: "false" },
    { id: 1, name: this.translationService.instant("PatientRecord.true"), value: "true" },
  ];

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Name: [(this.model && this.model.name) || "", Validators.required],
      Relationship: [(this.model && this.model.relationship.text) || "", Validators.required],
      Gender: [(this.model && this.genderList.find((x) => x.name === this.model.gender)) || "", Validators.required],
      Age: [(this.model && this.model.ageString) || "", Validators.required],
      Deceased: [
        (this.model && this.boolList.find((x) => !!x.id === this.model.deceasedBoolean)) || "",
        Validators.required,
      ],
      Reason: this.fb.array([]),
      Condition: this.fb.array([]),
      Notes: this.fb.array([]),
    });

    if (this.model && this.model.note && this.model.note.length !== 0) {
      this.model.note.forEach((note: any) => {
        (this.form.controls["Notes"] as FormArray).push(new FormControl(note.text, Validators.required));
      });
    }

    if (this.model && this.model.reasonCode && this.model.reasonCode.length !== 0) {
      this.model.reasonCode.forEach((reason: any) => {
        (this.form.controls["Reason"] as FormArray).push(new FormControl(reason.text, Validators.required));
      });
    }

    if (this.model && this.model.condition && this.model.condition.length !== 0) {
      this.model.condition.forEach((cond: any) => {
        (this.form.controls["Condition"] as FormArray).push(new FormControl(cond.code.text, Validators.required));
      });
    }
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    // this.form.reset();
    // this.form.setControl("Reason", this.fb.array([]));
    // this.form.setControl("Notes", this.fb.array([]));
    // this.form.setControl("Condition", this.fb.array([]));
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let memberModel: any = {};

    memberModel["resourceType"] = "FamilyMemberHistory";
    memberModel["status"] = "completed";
    memberModel["date"] = moment().format("YYYY-MM-DD");
    memberModel["name"] = this.form.controls["Name"].value;
    memberModel["relationship"] = {
      text: this.form.controls["Relationship"].value,
    };
    memberModel["gender"] = this.form.controls["Gender"].value.value;
    memberModel["ageString"] = this.form.controls["Age"].value;
    memberModel["deceasedBoolean"] = this.form.controls["Deceased"].value.value;
    if (this.form.controls["Reason"].value.length !== 0) {
      memberModel["reasonCode"] = this.form.controls["Reason"].value.map((x: any) => ({
        text: x,
      }));
    }
    if (this.form.controls["Notes"].value.length !== 0) {
      memberModel["note"] = this.form.controls["Notes"].value.map((x: any) => ({
        text: x,
      }));
    }
    if (this.form.controls["Condition"].value.length !== 0) {
      memberModel["condition"] = this.form.controls["Condition"].value.map((x: any) => ({
        code: { text: x },
      }));
    }

    if (this.model) {
      delete this.model.meta;
      memberModel = { ...this.model, ...memberModel };
    }

    this.success.emit(memberModel);
    this.form.reset();
    this.closed.emit();
  }
}
