import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../../core/toast.service';
import { Helpers } from '../../../services/app.helpers';

@Component({
  selector: 'app-invite-caregiver-form',
  templateUrl: './invite-caregiver-form.component.html',
  styleUrls: ['./invite-caregiver-form.component.css']
})
export class InviteCaregiverFormComponent implements OnInit {
  form!: FormGroup;

  @Input() patientId!: string;

  @Output() closed = new EventEmitter<any>();

  constructor(private httpClient: HttpClient, private toastService: ToastService, private fb: FormBuilder, private helpers: Helpers) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: ["", Validators.required]
    });
  }

  save() {
    Helpers.markFormGroupDirty(this.form);
    Helpers.markFormGroupTouched(this.form);

    if (!this.patientId) {
      this.toastService.errorMessage("You have not selected a patient");
      return;
    }

    if (!this.form.valid) {
      return;
    }

    const model = this.form.getRawValue();
    model.patientId = this.patientId;

    this.httpClient.post<any>(`${environment.serverPath}/api/related-person/send-invitation`, model).subscribe(res => {
      this.toastService.successMessage("The invitation was sent successfully!");
      this.closed.emit();
    }, err => {
      console.log(err);
    });
  }
}
