<div class="layout-container mt-2">
  <div class="record-menu d-none d-lg-block bottom-border">
    <div [routerLink]="'/patient-appointments'" routerLinkActive="active">
      {{'patientApopointments.RequestAppointment'|translate}}</div>
    <div [routerLink]="'/request-appointment'" routerLinkActive="active">
      {{'patientApopointments.AppointmentList'|translate}}
    </div>
  </div>
  <div class="d-lg-none">
    <div ngbDropdown placement="bottom-left" class="d-inline-block">
      <button class="btn btn-outline-primary" ngbDropdownToggle>
        <i class="fas fa-bars fa-fw"></i> {{'general.Menu'|translate}} </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem [routerLink]="'/patient-appointments'"
          routerLinkActive="active">{{'patientApopointments.RequestAppointment'|translate}}</button>
        <button ngbDropdownItem [routerLink]="'/request-appointment'"
          routerLinkActive="active">{{'patientApopointments.AppointmentList'|translate}}</button>
      </div>
    </div>
  </div>
  <div class="view-container m-2">
    <router-outlet></router-outlet>
  </div>
</div>