import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportExportPdfComponent } from "./report-export-pdf.component";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/authentication.guard";
import { DefaultModules } from "src/app/services/app.constants";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: ReportExportPdfComponent,
    data: { title: "sidebar.Calendar" },
  },
];

@NgModule({
  declarations: [ReportExportPdfComponent],
  imports: [...DefaultModules, RouterModule.forChild(routes)],
  exports: [ReportExportPdfComponent],
})
export class ReportExportPdfModule {}
