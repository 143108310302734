import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";

@Component({
  selector: "app-add-vaccine",
  templateUrl: "./add-vaccine.component.html",
  styleUrls: ["./add-vaccine.component.scss"],
})
export class AddVaccineComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Vaccine: [(this.model && this.model.vaccineCode.text) || "", Validators.required],
      Date: [(this.model && moment(this.model.date).toDate()) || "", Validators.required],
      ExpirationDate: [(this.model && moment(this.model.expirationDate).toDate()) || "", Validators.required],
      Notes: this.fb.array([]),
    });

    if (this.model && this.model.note && this.model.note.length !== 0) {
      this.model.note.forEach((note: any) => {
        (this.form.controls["Notes"] as FormArray).push(new FormControl(note.text, Validators.required));
      });
    }
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    // this.form.reset();
    // this.form.setControl("Notes", this.fb.array([]));
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let vaccineModel: any = {};

    vaccineModel["resourceType"] = "Immunization";
    vaccineModel["notGiven"] = false;
    vaccineModel["primarySource"] = true;
    vaccineModel["status"] = "completed";
    vaccineModel["vaccineCode"] = {
      text: this.form.controls["Vaccine"].value,
    };
    vaccineModel["date"] = moment(this.form.controls["Date"].value).format("YYYY-MM-DD");
    vaccineModel["expirationDate"] = moment(this.form.controls["ExpirationDate"].value).format("YYYY-MM-DD");
    if (this.form.controls["Notes"].value.length !== 0) {
      vaccineModel["note"] = this.form.controls["Notes"].value.map((x: any) => ({
        text: x,
      }));
    }

    if (this.model) {
      delete this.model.meta;
      vaccineModel = { ...this.model, ...vaccineModel };
    }

    this.success.emit(vaccineModel);
    this.form.reset();
    this.closed.emit();
  }
}
