<header class="clear">
  <div class="container-fluid">
    <div class="row header--container align-items-center">
      <!-- <div></div> -->

      <div>
        <div class="right-menu">
          <div class="d-inline-block relative">
            <!-- <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button class="btn btn-link" id="dropdownBasic3" ngbDropdownToggle>
                <span [style.background-position]="'0 ' + getLanguageImage()"></span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                <button ngbDropdownItem (click)="changeLanguage('en')">
                  {{ "general.English" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('el')">
                  {{ "general.Greek" | translate }}
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>




