export interface IRecord {
  id:
    | "general"
    | "blood"
    | "allergies"
    | "vaccines"
    | "procedures"
    | "medication"
    | "social_history"
    | "family_history"
    | "conditions"
    | "hospitalizations"
    | "reports"
    | "body-measurements"
    | "encounters";
  title?: string;
  items?: Array<IRecordItem>;
  action?: boolean;
  canAdd?: boolean;
  attrForAction?: Array<{ id: string; text: string }>;
}

export interface IRecordItem {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  valueArr?: string[];
  date?: Date;
  dateStr?: string;
  data?: object;
  min?: number;
  max?: number;
  normalValues?: {
    min?: number;
    max?: number;
    femaleMin: number;
    femaleMax?: number;
    maleMin?: number;
    maleMax?: number;
  };
  canAdd?: boolean;
  period?: object;
  code?: string;
  fhirId?: string;
}

export interface ICustomObservation {
  observationCode: IRecordItem;
  observationValue: number;
  observationDate: Date;
  observationCategory: string;
  observationCategoryName: string;
  observationUnit: string;
}

export interface IObservationCategory {
  id: string;
  category: string;
  label: string;
  code: string;
}

export enum FileType {
  Picture,
  Pdf,
}
