import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { catchError, switchMap, filter, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refrestTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService, private translateService: TranslateService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: any): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken()!);
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        // TODO: better handling here
        if (request.url.indexOf("login") > -1) {
          return throwError(error);
        }
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url.indexOf("refresh-token") > -1) {
            this.router.navigate(["/login"]);
            return of(null);
          }
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        "X-Site-Lang": this.translateService.currentLang || environment.defaultLang
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refrestTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap(token => {
          this.isRefreshing = false;
          this.refrestTokenSubject.next(token.refresh_token);
          return next.handle(this.addToken(request, token.access_token));
        })
      );
    } else {
      return this.refrestTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, this.authService.getJwtToken()!));
        })
      );
    }
  }
}