<div *ngIf="model" class="flex">
  <ng-scrollbar style="height: 60vh; max-height: 60vh; width: 100%" [autoPropagation]="true">
    <div *ngFor="let item of attributes" class="flex--container">
      <!-- All strings with boolean property-->
      <div *ngIf="getTypeOf(model[item.id]) === 'boolean'" class="flex--container__row">
        <div class="flex--container__item">{{ item.text }}:</div>
        <div>{{ model[item.id] }}</div>
      </div>
      <!-- All strings with text property-->
      <div *ngIf="getTypeOf(model[item.id]) === 'string'" class="flex--container__row">
        <div class="flex--container__item">{{ item.text }}:</div>
        <div>{{ model[item.id] }}</div>
      </div>
      <div *ngIf="getTypeOf(model[item.id]) === 'object'">
        <!-- All Objects with text property -->
        <div *ngIf="model[item.id].text; else coding" class="flex--container__row">
          <div class="flex--container__item">{{ item.text }}:</div>
          <div>{{ model[item.id].text }}</div>
        </div>
        <!-- All objects with coding property -->
        <ng-template #coding>
          <div *ngIf="model[item.id].coding" class="flex--container__row">
            <div class="flex--container__item">{{ item.text }}:</div>
            <div *ngFor="let codingItem of model[item.id].coding">
              {{ codingItem.display }}
            </div>
          </div>
        </ng-template>
        <!-- All Objects with value (and code) properties  -->
        <div *ngIf="model[item.id].value && model[item.id].code" class="flex--container__row">
          <div class="flex--container__item">{{ item.text }}:</div>
          <div>{{ model[item.id].value }} {{ model[item.id].code }}</div>
        </div>
        <!-- All objects with timings/period properties -->
        <div *ngIf="model[item.id].start" class="flex--container__row">
          <div class="flex--container__item">{{ item.text }}:</div>
          <div>
            {{ model[item.id].start }}
            {{ model[item.id].end ? "-- " + model[item.id].end : "-- " + ("medicalRecord.Now" | translate) }}
          </div>
        </div>
      </div>
      <!-- All Arrays with text, code, timing properties -->
      <div *ngIf="isArray(model[item.id])" class="flex--container__row">
        <div class="flex--container__item">{{ item.text }}:</div>
        <div>
          <div *ngFor="let innerItem of model[item.id]" class="flex--container__row-multi">
            <div *ngIf="innerItem.text">
              {{ innerItem.text }}
            </div>
            <div *ngIf="innerItem.code && innerItem.code.text">
              {{ innerItem.code.text }}
            </div>
            <div *ngIf="getTypeOf(innerItem) === 'string'">
              {{ innerItem }}
            </div>
            <div *ngIf="innerItem.timing && innerItem.timing.repeat.boundsPeriod.end">
              {{ innerItem.timing.repeat.boundsPeriod.start }} --
              {{ innerItem.timing.repeat.boundsPeriod.end }}
            </div>
            <div *ngIf="innerItem.timing && !innerItem.timing.repeat.boundsPeriod.end">
              {{ innerItem.timing.repeat.boundsPeriod.start }} -- {{ "medicalRecord.Now" | translate }}
            </div>
            <!-- All arrays that are extensions for attachments -->
            <div *ngIf="innerItem.url && innerItem.url === 'file_url'">
              <a [href]="serverPath + innerItem.valueString" target="_blank">
                {{ "general.openFile" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isArray(model[item.id])" class="flex--container__row">
        <div>
          <br />
          <div *ngFor="let innerItem of model[item.id]" class="flex--container__row-multi">
            <!-- All arrays that are extensions for encounters completed -->
            <ng-container *ngIf="innerItem.url && innerItem.url === 'completed'">
              <div class="extras-grid">
                <ng-container *ngFor="let item of encountersCompletedObject">
                  <div class="d-flex flex-column pr-4">
                    <div class="bold">{{ item.tr }}</div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>{{ item.value }}</div>
                      <label class="pl-2" for="">{{ item.unit }}</label>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
  <!-- <p-button
    [label]="'general.button.texts.Close' | translate"
    [ngStyle]="{ margin: 'auto', marginTop: '20px' }"
    icon="pi pi-check"
    iconPos="left"
    (click)="onClose()"
  ></p-button> -->
</div>
