import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-history-step",
  templateUrl: "./history-step.component.html",
  styleUrls: ["./history-step.component.scss"],
})
export class HistoryStepComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
