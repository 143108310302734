<form [formGroup]="form" (ngSubmit)="save()" style="height: 100%">
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.JoinDate" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        dateFormat="dd/mm/yy"
        formControlName="JoinDate"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
      ></p-calendar>
    </div>
  </div>
  <br />

  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.CompletionDate" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        dateFormat="dd/mm/yy"
        formControlName="CompletionDate"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
      ></p-calendar>
    </div>
  </div>
  <br />

  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.Reason" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Reason" type="text" />
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      [disabled]="isFormSubmitting"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
  </div>
</form>
