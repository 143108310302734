import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { TranslationWidth } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AssistantService {
  constructor(private httpClient: HttpClient, private translateService: TranslateService) {}

  getAnswerFromAgent(input: string, lang: string) {
    return this.httpClient.post<{ action: string; text: string; name: string }>(
      `${environment.serverPath}/api/assistant/send`,
      { message: input, lang }
    );
  }
}
