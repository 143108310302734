import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";

@Component({
  selector: "app-add-social-history",
  templateUrl: "./add-social-history.component.html",
  styleUrls: ["./add-social-history.component.scss"],
})
export class AddSocialHistoryComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Observation: [(this.model && this.model.code.text) || "", Validators.required],
      Date: [(this.model && moment(this.model.effectiveDateTime).toDate()) || "", Validators.required],
    });
  }

  onClose() {
    // this.form.reset();
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let procedureSocialHistoryModel: any = {};

    procedureSocialHistoryModel["resourceType"] = "Observation";
    procedureSocialHistoryModel["status"] = "final";
    procedureSocialHistoryModel["code"] = [
      {
        coding: [
          {
            system: "http://clinical.iti.org",
            code: this.form.controls["Observation"].value,
            display: this.form.controls["Observation"].value,
          },
        ],
        text: this.form.controls["Observation"].value,
      },
    ];
    procedureSocialHistoryModel["category"] = [
      {
        coding: [
          {
            system: "http://hl7.org/fhir/observation-category",
            code: "social-history",
            display: "Social History",
          },
        ],
        text: "Social History",
      },
    ];
    procedureSocialHistoryModel["effectiveDateTime"] = moment(this.form.controls["Date"].value).format("YYYY-MM-DD");

    if (this.model) {
      delete this.model.meta;
      procedureSocialHistoryModel = { ...this.model, ...procedureSocialHistoryModel };
    }

    this.success.emit(procedureSocialHistoryModel);
    this.form.reset();
    this.closed.emit();
  }
}
