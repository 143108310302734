import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { TranslateModule } from "@ngx-translate/core";
import { AccountStepComponent } from "./account-step/account-step.component";
import { ConditionsStepComponent } from "./conditions-step/conditions-step.component";
import { MedicalRecordModule } from "../medical-record/medical-record.module";
import { HistoryStepComponent } from "./history-step/history-step.component";
import { ProceduresStepComponent } from "./procedures-step/procedures-step.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MedicalRecordModule,
  ],
  declarations: [AccountStepComponent, ConditionsStepComponent, HistoryStepComponent, ProceduresStepComponent],
  exports: [AccountStepComponent, ConditionsStepComponent, HistoryStepComponent, ProceduresStepComponent],
})
export class StepsComponentsModule {}
