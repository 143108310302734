import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isUserAuthenticated()) {
      const userRoles = this.authService.getUserRoles();

      if (!userRoles) {
        this.router.navigate(["/login"]);
        return false;
      }

      if (next.data["roles"] && !next.data["roles"].some((role: any) => userRoles.includes(role))) {
        this.router.navigate(["/login"]);
        return false;
      }

      return true;
    }

    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;

  }
}
