import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MessageBroadcasterService, MessageModel } from "src/app/services/message.service";
import { Helpers } from "src/app/services/app.helpers";
import { IChatUser } from "./chat.models";
import { BehaviorSubject } from "rxjs";

export enum RoomMessageType {
  Message,
  Attachement,
}

export enum RoomMessageShowStatus {
  Viewed,
  NotViewed,
}

export interface ChatUser {
  id: string;
  fhirId: string;
  fullname: string;
  type: string;
}

export interface ChatRoom {
  Id: number;
  Name: string;
  Users: Array<ChatUser>;
  LastMessage: string;
  LastMessageActorName: string;
  LastMessageActorId: string;
  LastMessageType: RoomMessageType;
  LastMessageStatus: RoomMessageShowStatus;
  LastMessageAt: Date;
}

export interface RoomMessage {
  Id: number;
  RoomId: number;
  Message: string;
  SenderId: string;
  SenderName: string;
  DateSent: Date;
  ShowedStatus: RoomMessageShowStatus;
  MessageType: RoomMessageType;
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private newChatMessage: EventEmitter<RoomMessage> = new EventEmitter<RoomMessage>();

  unreadMessagesRoomIds = new BehaviorSubject(null);

  get newChatMessageNotification() {
    return this.newChatMessage;
  }

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private messageService: MessageBroadcasterService
  ) {
    this.messageService.onMessage.subscribe((message: any) => {
      if (typeof (this as any)["on" + Helpers.capitalizeFirstLetter(message.event)] === "function") {
        (this as any)["on" + Helpers.capitalizeFirstLetter(message.event)](message);
      }
    });
  }

  public getUserRooms() {
    return this.http.get<Array<ChatRoom>>(`${environment.serverPath}/api/chat/user-rooms`);
  }

  public getCareTeamOnlyUserRooms() {
    return this.http.get<Array<ChatRoom>>(`${environment.serverPath}/api/chat/care-team-only-user-rooms`);
  }

  public getUserRoomMessages(roomId: number) {
    return this.http.get<Array<RoomMessage>>(`${environment.serverPath}/api/chat/user-room-messages/${roomId}`);
  }

  public sendTextMessage(roomId: number, message: string) {
    return this.http.post(`${environment.serverPath}/api/chat/send`, {
      RoomId: roomId,
      Message: message,
      MessageType: RoomMessageType.Message,
    });
  }

  public sendFileMessage(roomId: number, filePath: string) {
    return this.http.post(`${environment.serverPath}/api/chat/send`, {
      RoomId: roomId,
      Message: filePath,
      MessageType: RoomMessageType.Attachement,
    });
  }

  public onNewChatMessage(message: MessageModel) {
    this.newChatMessage.emit(message.data);
  }

  public searhUsers(searchStr: string) {
    return this.http.get<Array<IChatUser>>(`${environment.serverPath}/api/chat/search-users?str=${searchStr}`);
  }

  public createRoom(groupUsers: Array<IChatUser>) {
    return this.http.post(`${environment.serverPath}/api/chat/create-group`, groupUsers);
  }

  public setMessagesRead(roomId: number) {
    return this.http.post(`${environment.serverPath}/api/chat/set-read/${roomId}`, {});
  }

  public setMessagesReadForUser(roomId: number) {
    return this.http.post(`${environment.serverPath}/api/chat/set-read-for-user/${roomId}`, {});
  }

  public checkIfUserHasUnread() {
    this.http.get<any>(`${environment.serverPath}/api/chat/check-if-user-has-unread`).subscribe((res) => {
      this.unreadMessagesRoomIds.next(res);
      return;
    });
  }
}
