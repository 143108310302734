import { Component, OnInit, Input } from "@angular/core";
import { AcountService } from "src/app/pages/acount/acount.service";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Helpers } from "src/app/services/app.helpers";
import { Lookup } from "src/app/core/models/lookup.model";
import * as moment from "moment";
import { ToastService } from "src/app/core/toast.service";
import {
  CHILDREN,
  EDUCATIONAL_LEVELS,
  Extensions,
  LIVE_ALONE,
  MARITAL_STATUS,
  OCCUPATION,
  TECH_FAMILIARITY,
} from "src/app/core/enum/Account";
import * as fhir from "fhir-stu3";

@Component({
  selector: "app-account-step",
  templateUrl: "./account-step.component.html",
  styleUrls: ["./account-step.component.scss"],
})
export class AccountStepComponent implements OnInit {
  @Input() patientId!: string;
  patientInfo!: fhir.Practitioner;
  form!: FormGroup;
  genders: Array<Lookup> = [];
  educationalLevels: Array<Lookup> = [];
  techFamiliarity: Array<Lookup> = [];
  liveAlone: Array<Lookup> = [];
  children: Array<Lookup> = [];
  maritalStatus: Array<Lookup> = [];
  occupation: Array<Lookup> = [];
  isFormSubmitting = false;
  maxDateOfBirth = new Date();

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private accountService: AcountService,
    private helpers: Helpers,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.updateOptions();
    this.translateService.onLangChange.subscribe((x) => {
      this.updateOptions();
    });
    this.createForm();

    this.accountService.getPatientInfoById(this.patientId).subscribe((patientInfo) => {
      this.patientInfo = patientInfo;
      this.patchValue();
    });
  }

  updateOptions() {
    this.genders = [
      { id: "male", name: this.translateService.instant("general.male") },
      { id: "female", name: this.translateService.instant("general.female") },
    ];

    this.educationalLevels = EDUCATIONAL_LEVELS.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));

    this.techFamiliarity = TECH_FAMILIARITY.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));

    this.liveAlone = LIVE_ALONE.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));

    this.children = CHILDREN.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));

    this.maritalStatus = MARITAL_STATUS.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));

    this.occupation = OCCUPATION.map((x, idx) => ({
      id: idx,
      name: this.translateService.instant("account." + x),
    }));
  }

  createForm() {
    this.form = this.fb.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      dateOfBirth: [new Date(), Validators.required],
      phone: ["", Validators.required],
      address: [""],
      city: [""],
      postcode: [""],
      gender: [this.genders[0], Validators.required],
      nationality: [""],
      educationalLevel: [this.educationalLevels[0]],
      techFamiliarity: [this.techFamiliarity[0]],
      liveAlone: [this.liveAlone[0]],
      children: [this.children[0]],
      maritalStatus: [this.maritalStatus[0]],
      occupation: [this.occupation[0]],
    });
  }

  save() {
    Helpers.markFormGroupDirty(this.form);
    Helpers.markFormGroupTouched(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translateService.instant("account.invalidForm"));
      return;
    }

    const model = this.form.getRawValue();
    model.gender = model.gender.id;
    model.educationalLevel = model.educationalLevel.id;
    model.techFamiliarity = model.techFamiliarity.id;
    model.liveAlone = model.liveAlone.id;
    model.children = model.children.id;
    model.maritalStatus = model.maritalStatus.id;
    model.occupation = model.occupation.id;

    this.isFormSubmitting = true;
    this.accountService.updatePatientProfileById(model, this.patientId).subscribe(
      (x) => {
        this.isFormSubmitting = false;
        if (this.helpers.responseHasErrors(x, true)) return;
        this.toastService.successMessage(this.translateService.instant("account.updateSuccess"));
      },
      (err) => {
        this.toastService.generalErrorMessage();
        this.isFormSubmitting = false;
      }
    );
  }

  patchValue() {
    if (
      this.patientInfo.name &&
      this.patientInfo.name.length > 0 &&
      this.patientInfo.name[0].given &&
      this.patientInfo.name[0].given.length > 0
    ) {
      this.form?.get("firstname")?.setValue(this.patientInfo.name[0].given[0]);
    }

    if (this.patientInfo.name && this.patientInfo.name.length > 0 && this.patientInfo.name[0].family) {
      this.form.get("lastname")?.setValue(this.patientInfo.name[0].family);
    }

    if (this.patientInfo.birthDate) {
      this.form.get("dateOfBirth")?.setValue(moment(this.patientInfo.birthDate).toDate());
    }

    if (this.patientInfo.telecom && this.patientInfo.telecom.find((x) => x.system === "phone" && x.use === "home")) {
      this.form
        .get("phone")
        ?.setValue(this.patientInfo.telecom.find((x) => x.system === "phone" && x.use === "home")?.value);
    }

    if (
      this.patientInfo.address &&
      this.patientInfo.address.length > 0 &&
      this.patientInfo.address[0].line &&
      this.patientInfo.address[0].line.length > 0
    ) {
      this.form.get("address")?.setValue(this.patientInfo.address[0].line[0]);
    }

    if (this.patientInfo.address && this.patientInfo.address.length > 0) {
      this.form.get("postcode")?.setValue(this.patientInfo.address[0].postalCode);
    }

    if (this.patientInfo.address && this.patientInfo.address.length > 0) {
      this.form.get("city")?.setValue(this.patientInfo.address[0].city);
    }

    if (this.patientInfo.gender) {
      this.form.get("gender")?.setValue(this.genders.find((x) => x.id === this.patientInfo.gender));
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.nationality)) {
      this.form
        .get("nationality")
        ?.setValue(this.patientInfo.extension.find((x) => x.url === Extensions.nationality)?.valueString);
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.educationalLevel)) {
      this.form
        .get("educationalLevel")
        ?.setValue(
          this.educationalLevels.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.educationalLevel)?.valueInteger
          )
        );
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.techFamiliarity)) {
      this.form
        .get("techFamiliarity")
        ?.setValue(
          this.techFamiliarity.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.techFamiliarity)?.valueInteger
          )
        );
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.liveAlone)) {
      this.form
        .get("liveAlone")
        ?.setValue(
          this.liveAlone.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.liveAlone)?.valueInteger
          )
        );
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.children)) {
      this.form
        .get("children")
        ?.setValue(
          this.children.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.children)?.valueInteger
          )
        );
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.maritalStatus)) {
      this.form
        .get("maritalStatus")
        ?.setValue(
          this.maritalStatus.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.maritalStatus)?.valueInteger
          )
        );
    }

    if (this.patientInfo.extension && this.patientInfo.extension.find((x) => x.url === Extensions.occupation)) {
      this.form
        .get("occupation")
        ?.setValue(
          this.occupation.find(
            (x) => x.id === this.patientInfo.extension!.find((x) => x.url === Extensions.occupation)?.valueInteger
          )
        );
    }
  }
}
