<header [@slideInOut]="isOpen" class="clear" *ngIf="isHeaderVisible">
  <div class="container-fluid">
    <div class="row header--container align-items-center">
      <div>
        <a (click)="toggleMenu()" class="sidebar-toggler"><i class="fas fa-bars"></i></a>
      </div>

      <div>
        <div class="right-menu">
          <div class="d-inline-block relative" style="margin-right: 25px">
            <button
              class="btn btn-link header-btn"
              [class.custom-badge]="notificationsCount > 0"
              [attr.data-count]="notificationsCount"
              [pTooltip]="notificationsCount === 0 ? ('headerPage.noNotificationsTooltip' | translate) : null"
              tooltipPosition="bottom"
              #notificationsIcon
            >
              <i class="fas fa-bell"></i>
            </button>
            <div class="notification-list-popup iti-popup" *ngIf="isNotificationPopupVisible" #notificationsContainer>
              <h3 class="text-center popup-header uppercase">
                {{ "NotFixedNotificationsHeader" | translate }}
              </h3>
              <ul class="contain-scrolling">
                <li
                  *ngFor="let notification of notifications; let i = index"
                  [ngClass]="[i % 2 == 1 ? 'odd' : 'even']"
                  class="pointer"
                  [ngStyle]="{ background: notification.isCritical === true ? '#ffe8e8' : '#ffffff' }"
                >
                  <div class="notification-header">
                    <i
                      >{{ notification.Patient ? notification.Patient.fullname : "" }}
                      <span *ngIf="notification.isCritical === true" style="font-weight: normal">
                        - {{ "headerPage.criticalEvent" | translate }}</span
                      ></i
                    >
                  </div>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      {{ notification.Message | translate }}
                    </div>
                  </div>
                  <ng-container *ngIf="notification.NotificationType === 'ADoctorRequestedASecondOpinion'">
                    <div>
                      <label class="bold">{{ "Message" | translate }}:</label> {{ notification.data.notes }}
                    </div>
                    <div>
                      <label class="bold">{{ "Patient" | translate }}</label
                      >: {{ notification.data.patientName }}
                    </div>
                  </ng-container>
                  <div class="notification-footer d-flex">
                    <small class="flex-grow-1">{{ notification.createdStr }}</small>
                    <a
                      [title]="'MarkNotificationFixed' | translate"
                      class="font-blue-green pointer"
                      (click)="markNotificationFixed(notification)"
                    >
                      <i class="fas fa-wrench"></i>
                    </a>
                    <span class="p-1"></span>
                  </div>
                </li>
              </ul>
              <div class="toolbar text-center d-flex flex-no-wrap">
                <button
                  *ngIf="notificationsCount < 6"
                  class="pointer notification-anchor"
                  (click)="navigateToNotifications()"
                >
                  {{ "headerPage.goToNotifications" | translate }}
                </button>
                <button
                  *ngIf="notificationsCount > 5"
                  class="pointer notification-anchor"
                  (click)="navigateToNotifications()"
                >
                  {{ "headerPage.goToNotifications" | translate }} (+{{ notificationsCount - 5 }}
                  {{ "headerPage.Notifications" | translate }})
                </button>
                <button
                  *ngIf="authService.hasRole('ROLE_DOCTOR')"
                  class="pointer notification-anchor"
                  (click)="navigateToCriticalNotifications()"
                >
                  {{ "GoToCriticalNotifications" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="d-inline-block relative">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button
                class="btn btn-link"
                id="dropdownBasic3"
                ngbDropdownToggle
                style="
                  padding-left: 25px;
                  padding-right: 25px;
                  width: auto !important;
                  background: var(--header-button-background);
                  color: var(--header-font-color) !important;
                "
              >
                <span style="color: var(--header-font-color) !important; display: inline-block; padding-right: 5px">{{
                  getLanguageName()
                }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
                <button ngbDropdownItem (click)="changeLanguage('en')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/UKFlag.png" alt="" />
                    <div>{{ "general.language_en" | translate }}</div>
                  </div>
                </button>
                <button ngbDropdownItem (click)="changeLanguage('el')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/GreekFlag.png" alt="" />
                    <div>{{ "general.language_el" | translate }}</div>
                  </div>
                </button>
                <button ngbDropdownItem (click)="changeLanguage('sv')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/SwedishFlagpng.png" alt="" />
                    <div>{{ "general.language_sv" | translate }}</div>
                  </div>
                </button>
                <button ngbDropdownItem (click)="changeLanguage('it')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/ItalianFlag.png" alt="" />
                    <div>{{ "general.language_it" | translate }}</div>
                  </div>
                </button>
                <button ngbDropdownItem (click)="changeLanguage('pt')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/PortugalFlag.png" alt="" />
                    <div>{{ "general.language_pt" | translate }}</div>
                  </div>
                </button>
                <button ngbDropdownItem (click)="changeLanguage('tr')">
                  <div class="d-flex align-items-center">
                    <img class="language-flag" src="/assets/images/flags/TurkishFlag.png" alt="" />
                    <div>{{ "general.language_tr" | translate }}</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="d-inline-block relative">
            <button
              style="border-radius: 40px; margin: 0.5rem; background: var(--header-button-background)"
              [disabled]="disableSpeechControls"
              class="btn btn-link"
              (click)="logoutUser()"
            >
              <i class="fas fa-power-off"></i>
            </button>
          </div>
          <!-- <div class="d-inline-block relative">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button class="btn btn-link header-btn" id="dropdownBasic2" ngbDropdownToggle>
                <i class="fas fa-user fa-fw"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <div class="border-bottom text-center mb-1 pb-1">{{ username }}</div>
                <button ngbDropdownItem [routerLink]="'/account'">
                  <i class="fas fa-user fa-fw"></i> {{ "general.account" | translate }}
                </button>
                <button ngbDropdownItem (click)="logoutUser()">
                  <i class="fas fa-lock fa-fw"></i> {{ "general.logout" | translate }}
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</header>
