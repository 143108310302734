<form [formGroup]="form" (ngSubmit)="save()" *ngIf="fileTypes && fileTypes.length !== 0">
  <ng-scrollbar style="height: 50vh; max-height: 50vh" [autoPropagation]="true">
    <div class="p-grid">
      <div class="p-col-2">
        <label>{{ "medicalRecord.diagnosis" | translate }}:</label>
      </div>
      <div class="p-col-2">
        <input pInputText formControlName="reason" type="text" />
      </div>
    </div>
    <br />
    <div class="d-flex justify-content-around align-items-center">
      <div class="flex-grow-1 pr-4">
        <label>{{ "medicalRecord.admissionDate" | translate }}:</label>
        <p-calendar
          formControlName="start"
          yearNavigator="true"
          monthNavigator="true"
          yearRange="1910:2030"
          appendTo="body"
        ></p-calendar>
      </div>
      <div class="flex-grow-1">
        <div class="d-flex justify-content-between align-items-center">
          <label>{{ "medicalRecord.dischargeDate" | translate }}:</label>
          <div class="d-flex justify-content-center align-items-center" formGroupName="dischargeCompleted">
            <p-checkbox on binary="true" formControlName="completed"></p-checkbox>
            <span class="pl-2">{{ "medicalRecord.dischargeCompleted" | translate }}</span>
          </div>
        </div>
        <p-calendar
          formControlName="end"
          yearNavigator="true"
          monthNavigator="true"
          yearRange="1910:2030"
          appendTo="body"
        ></p-calendar>
      </div>
    </div>
    <br />
    <div
      class="extras-grid"
      *ngIf="dischargeCompletedControls.get('completed').value"
      formGroupName="dischargeCompleted"
    >
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Medication after discharge" | translate }}</div>
        <input formControlName="medicationAfterDischarge" pInputText type="text" />
      </div>
      <div class="d-flex flex-column pr-4">
        <div>
          {{
            "medicalRecord.encounter.For medication changes during the hospitalization period please visit the History tab"
              | translate
          }}
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.NYHA Class" | translate }}</div>
        <input formControlName="NYHAClass" pInputText type="text" />
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Dry weight" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText formControlName="dryWeight" type="text" />
          <label class="pl-2" for="">Kg</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Systolic blood pressure" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText type="text" formControlName="systolicBloodPressure" />
          <label class="pl-2" for="">mmHg</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Diastolic blood pressure" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText formControlName="diastolicBloodPressure" type="text" />
          <label class="pl-2" for="">mmHg</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Heart rate" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText formControlName="heartRate" type="text" />
          <label class="pl-2" for="">bpm</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Haemoglobin" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText type="text" formControlName="haemoglobin" />
          <label class="pl-2" for="">g/dL</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Creatinine" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText type="text" formControlName="creatinine" />
          <label class="pl-2" for="">mg/dL</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Urea" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText type="text" formControlName="urea" />
          <label class="pl-2" for="">mg/dL</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.eGFR" | translate }}</div>
        <div class="d-flex justify-content-between align-items-center">
          <input pInputText type="text" formControlName="eGFR" />
          <label class="pl-2" for="">mL/min/1.73m2</label>
        </div>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Advice for follow-ups" | translate }}</div>
        <input pInputText type="text" formControlName="followUp" />
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Further notes" | translate }}</div>
        <textarea pInputText type="text" formControlName="notes"></textarea>
      </div>
      <div class="d-flex flex-column pr-4">
        <div>{{ "medicalRecord.encounter.Point of contact" | translate }}</div>
        <input pInputText type="text" formControlName="poc" />
      </div>
    </div>
    <div class="d-flex justify-content-between" *ngIf="fileExtension">
      <label class="item-label">{{ "activityItem.Attachments" | translate }}:</label>
      <a class="item-content mr-1" [href]="serverPath + fileExtension.valueString" target="_blank">
        {{ "activityItem.Attachments" | translate }} 1
      </a>
      <button
        pButton
        type="button"
        (click)="removeAttachment(fileExtension, idx)"
        icon="pi pi-times"
        class="p-button-danger p-button-outlined"
        style="border: none"
      ></button>
    </div>
    <div class="row" *ngIf="!model || !model.extension || model.extension.length === 0">
      <div class="col-md-12">
        <label style="font-size: 1rem" class="d-block">{{ "medicalRecord.IncludeAttachment" | translate }}:</label>
        <!-- <label class="d-block">{{ "medicalRecord.SelectFileType" | translate }}</label> -->
        <p-dropdown
          [options]="fileTypes"
          formControlName="FileType"
          optionLabel="name"
          dataKey="id"
          [placeholder]="'medicalRecord.SelectFileType' | translate"
          [showClear]="true"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>
    <div
      class="row"
      *ngIf="(!model || !model.extension || model.extension.length === 0) && selectedFileTypeId !== undefined"
    >
      <div class="col-md-12">
        <label class="d-block">{{ "educationPage.popup.labels.File" | translate }}</label>
        <p-fileUpload
          *ngIf="selectedFileTypeId === 0"
          name="myPicture"
          auto="auto"
          customUpload="true"
          (uploadHandler)="uploadFile($event)"
          accept="image/*"
        ></p-fileUpload>
        <p-fileUpload
          *ngIf="selectedFileTypeId === 1"
          name="myPdf"
          auto="auto"
          customUpload="true"
          (uploadHandler)="uploadFile($event)"
          accept="application/pdf"
        ></p-fileUpload>
      </div>
    </div>
    <br />
  </ng-scrollbar>
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      [disabled]="isFormSubmitting"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <!-- <button (click)="onClose()" [disabled]="isFormSubmitting" class="p-button-rounded p-button-danger"
      icon="fas fa-times" [label]="'general.button.texts.Cancel' | translate" type="button" pButton></button> -->
  </div>
</form>
