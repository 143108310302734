import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ToastService } from "src/app/core/toast.service";
import { Helpers } from "src/app/services/app.helpers";

@Component({
  selector: "app-add-participation-info",
  templateUrl: "./add-participation-info.component.html",
  styleUrls: ["./add-participation-info.component.scss"],
})
export class AddParticipationInfoComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  isFormSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  createForm() {
    this.form = this.fb.group({
      JoinDate: [
        (this.model && this.model.JoinDate && moment(this.model.JoinDate).toDate()) || "",
        Validators.required,
      ],
      CompletionDate: [(this.model && this.model.CompletionDate && moment(this.model.CompletionDate).toDate()) || ""],
      Reason: [(this.model && this.model.Reason) || ""],
    });
  }

  onClose() {
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translationService.instant("alerts.INVALID_FORM_CORRECT_THEM"));
      return;
    }

    this.isFormSubmitting = true;

    const result = this.form.getRawValue();

    this.success.emit(result);
    this.isFormSubmitting = false;
    this.form.reset();
    this.closed.emit();
  }
}
