import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as fhir from "fhir-stu3";

@Injectable({
  providedIn: "root",
})
export class AcountService {
  constructor(private httpClient: HttpClient) {}

  public getUserInfo() {
    return this.httpClient.get<fhir.Practitioner>(`${environment.serverPath}/api/doctors/doctor-details`);
  }

  public getPatientInfo() {
    return this.httpClient.get<fhir.Patient>(`${environment.serverPath}/api/patient/patient-info`);
  }

  public getPatientInfoById(patientId: string) {
    return this.httpClient.get<fhir.Patient>(`${environment.serverPath}/api/patient/patient-info/${patientId}`);
  }

  public updateProfile(model: any) {
    return this.httpClient.post(`${environment.serverPath}/api/doctors/doctor-details`, model);
  }

  public updatePatientProfile(model: any) {
    return this.httpClient.put(`${environment.serverPath}/api/patient/patient-info`, model);
  }

  public updatePatientProfileById(model: any, patientId: string) {
    return this.httpClient.put(`${environment.serverPath}/api/patient/patient-info/${patientId}`, model);
  }
}
