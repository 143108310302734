<form [formGroup]="form" (ngSubmit)="save()">
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.medication" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Medication" type="text" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.start" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        formControlName="From"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
      ></p-calendar>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.end" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        formControlName="Until"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
      ></p-calendar>
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <!-- <button
      (click)="onClose()"
      class="p-button-rounded p-button-danger"
      icon="fas fa-times"
      [label]="'general.button.texts.Cancel' | translate"
      type="button"
      pButton
    ></button> -->
  </div>
</form>
