<div class="layout-container mt-2">
  <div class="record-menu d-none d-lg-block bottom-border">
    <div [routerLink]="'/compare/moment-in-time'" routerLinkActive="active">
      {{ "patientsCompare.MomentItTime" | translate }}
    </div>
    <div [routerLink]="'/compare/range-in-time'" routerLinkActive="active">
      {{ "patientsCompare.RangeInTime" | translate }}
    </div>
    <div [routerLink]="'/compare/demographics'" routerLinkActive="active">
      {{ "patientsCompare.Demographics" | translate }}
    </div>
    <div [routerLink]="'/compare/median'" routerLinkActive="active">{{ "patientsCompare.Median" | translate }}</div>
  </div>
</div>
<div class="d-lg-none">
  <div ngbDropdown placement="bottom-left" class="d-inline-block">
    <button class="btn btn-outline-primary" ngbDropdownToggle>
      <i class="fas fa-bars fa-fw"></i> {{ "general.Menu" | translate }}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem [routerLink]="'/compare/moment-in-time'" routerLinkActive="active">
        {{ "patientsCompare.MomentItTime" | translate }}
      </button>
      <button ngbDropdownItem [routerLink]="'/compare/range-in-time'" routerLinkActive="active">
        {{ "patientsCompare.RangeInTime" | translate }}
      </button>
      <button ngbDropdownItem [routerLink]="'/compare/demographics'" routerLinkActive="active">
        {{ "patientsCompare.Demographics" | translate }}
      </button>
      <button ngbDropdownItem [routerLink]="'/compare/median'" routerLinkActive="active">
        {{ "patientsCompare.Median" | translate }}
      </button>
    </div>
  </div>
</div>
<div>
  <div class="m-2"></div>
  <div class="view-container">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <label class="bold d-block" style="vertical-align: text-bottom">{{
              "patientsCompare.SelectPatients" | translate
            }}</label>
            <div class="p-fluid">
              <p-multiSelect
                [options]="dataSource"
                [(ngModel)]="selectedPatients"
                optionLabel="Fullname"
                [defaultLabel]="'patientsCompare.SelectPatients' | translate"
                (onChange)="selectedPatientsChanged($event)"
              ></p-multiSelect>
            </div>
          </div>
          <div class="col-md-9">
            <label class="text-center bold d-block">
              {{ "patientsCompare.ViewPatientsDataOverTime" | translate }}
            </label>
          </div>
        </div>
        <div class="m-2"></div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
