import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, tap, mapTo, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Helpers } from "./app.helpers";
import { Role } from "../core/enum/Role";
import { ToastService } from "../core/toast.service";

export interface Credentials {
  email: string;
  password: string;
  grant_type: string;
  client_id: string;
}

export interface RefreshTokenRequestModel {
  token: string;
}

export interface LoginSuccessModel {
  isVerified?: any;
  userName: string;
  userId: string;
  access_token: string;
  refresh_token: string;
  ".expires": string;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public loginSuccess: EventEmitter<LoginSuccessModel> = new EventEmitter<LoginSuccessModel>();
  public logoutSuccess: EventEmitter<string> = new EventEmitter<string>();

  private userStorageKey = "user";
  private jwtTokenKey = "JWT_TOKEN";
  private refreshTokenKey = "refresh_token";
  private readonly clientId = "ngAuthApp";
  private readonly loginGrantType = "password";
  private readonly refreshTokenGrantType = "refresh_token";
  private loggedUser!: string | null;

  constructor(private http: HttpClient, private toastService: ToastService) {}

  public login(model: Credentials): Observable<boolean> {
    model.grant_type = this.loginGrantType;
    model.client_id = this.clientId;
    return this.http
      .post<LoginSuccessModel>(`${environment.serverPath}/auth/login`, Helpers.jsonToFormEncoded(model), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .pipe(
        tap((x) => {
          if (!x?.isVerified) {
            this.toastService.errorMessage("You are not yet verified. You have to wait to be verified first.");
            throw new Error("NOT_VERIFIED");
          }
          this.doLoginUser(model.email, x);
        }),
        mapTo(true),
        catchError((error) => {
          return of(false);
        })
      );
  }

  isDoctor() {
    return this.hasRole("ROLE_DOCTOR");
  }

  public logout() {
    this.doLogoutUser();
    return of(true);
  }

  public refreshToken(): Observable<LoginSuccessModel> {
    const model: RefreshTokenRequestModel = {
      token: this.getRefreshToken() as any,
    };

    return this.http.post<LoginSuccessModel>(`${environment.serverPath}/auth/refresh-token`, model).pipe(
      tap((x) => {
        this.storeTokens(x);
      })
    );
  }

  public getTokenModel() {
    if (localStorage.getItem(this.userStorageKey)) {
      try {
        return JSON.parse(localStorage.getItem(this.userStorageKey)!);
      } catch (err) {
        return undefined;
      }
    }
    return undefined;
  }

  public getUserId() {
    if (this.isUserAuthenticated()) {
      return JSON.parse(localStorage.getItem(this.userStorageKey)!).userId;
    }

    return undefined;
  }

  public getUserRoles() {
    if (this.isUserAuthenticated()) {
      return JSON.parse(localStorage.getItem(this.userStorageKey)!).roles;
    }

    return undefined;
  }

  public hasRole(role: string) {
    const u = this.getUser();
    if (!u) return false;

    if (this.getUser().roles.includes(role)) return true;

    return false;
  }

  public getUserFhirId(): string {
    if (this.isUserAuthenticated()) {
      return JSON.parse(localStorage.getItem(this.userStorageKey)!).fhirId;
    }

    return "";
  }

  public getUser() {
    if (this.isUserAuthenticated()) {
      return JSON.parse(localStorage.getItem(this.userStorageKey)!);
    }

    return undefined;
  }

  public getUserType(): "Practitioner" | "Patient" | null {
    if (this.isUserAuthenticated()) {
      if (this.hasRole(Role.Doctor)) return "Practitioner";
      else if (this.hasRole(Role.Patient)) return "Patient";
    }

    return null;
  }

  public getJwtToken() {
    return localStorage.getItem(this.jwtTokenKey);
  }

  public isUserAuthenticated(): boolean {
    if (!this.getJwtToken()) return false;

    return true;
  }

  public getFullname() {
    if (this.isUserAuthenticated()) {
      return JSON.parse(localStorage.getItem(this.userStorageKey)!).fullname;
    }

    return "";
  }

  private getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  private doLoginUser(username: string, loginResponse: LoginSuccessModel) {
    this.loggedUser = username;
    this.storeTokens(loginResponse);
    this.loginSuccess.emit(loginResponse);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.logoutSuccess.emit(localStorage.getItem(this.jwtTokenKey)!);
    this.removeTokens();
  }

  private removeTokens() {
    localStorage.removeItem(this.jwtTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.userStorageKey);
  }

  private storeTokens(loginResponse: LoginSuccessModel) {
    localStorage.setItem(this.jwtTokenKey, loginResponse.access_token);
    localStorage.setItem(this.refreshTokenKey, loginResponse.refresh_token);
    localStorage.setItem(this.userStorageKey, JSON.stringify(loginResponse));
  }

  public forgotPassword(email: string) {
    return this.http.post(`${environment.serverPath}/auth/forgot-password`, { email });
  }

  public changePassword(postModel: any) {
    return this.http.post(`${environment.serverPath}/auth/change-password`, postModel);
  }
}
