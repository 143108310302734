import { Injectable, EventEmitter } from "@angular/core";

export enum MessageTypes {
  CreateEntity,
  UpdateEntity,
  RemoveEntity,
  SimpleMessage,
}

export interface MessageModel {
  messageType?: MessageTypes;
  message?: string;
  source?: "socket" | "internalMessage" | "event";
  data?: any;
  event?: string;
}

@Injectable({
  providedIn: "root"
})
export class MessageBroadcasterService {
  private messageHub: EventEmitter<MessageModel> = new EventEmitter<MessageModel>();
  patientCriticalEvent: EventEmitter<any> = new EventEmitter<any>();
  patientConsentAccepted: EventEmitter<any> = new EventEmitter<any>();
  patientConsentEvent: EventEmitter<any> = new EventEmitter<any>();
  appointmentsUpdated: EventEmitter<any> = new EventEmitter<any>();
  appointmentAccepted: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  broadcastMessage(message: MessageModel) {
    this.messageHub.emit(message);
  }

  get onMessage() {
    return this.messageHub;
  }
}
