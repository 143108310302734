import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GGDraggableDirective } from "./ggdraggable.directive";

@NgModule({
  declarations: [GGDraggableDirective],
  imports: [CommonModule],
  exports: [GGDraggableDirective],
})
export class DraggableModule {}
