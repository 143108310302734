import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class FileUploadService {
  constructor(private http: HttpClient) { }

  public upload(file: any) {
    const fd = new FormData();
    fd.append("file", file);
    return this.http.post(`${environment.serverPath}/api/upload`, fd);
  }
}
