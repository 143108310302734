import { Injectable, EventEmitter } from "@angular/core"; import { BreadcrumbModel } from "./breadcrumb.model";

@Injectable({
    providedIn: "root"
})
export class BreadcrumbService {
    private dataUpdated: EventEmitter<Array<BreadcrumbModel>> = new EventEmitter<Array<BreadcrumbModel>>();

    private _data: Array<BreadcrumbModel> = [];
    get data(): Array<BreadcrumbModel> {
        return this._data;
    }
    set data(value: Array<BreadcrumbModel>) {
        if (this._data !== value) {
            this._data = value;
            this.dataUpdated.emit(this._data);
        }
    }

    constructor() { }

    get onDataUpdated(): EventEmitter<Array<BreadcrumbModel>> {
        return this.dataUpdated;
    }
}