import { Injectable } from "@angular/core";

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  public eventEmitter(eventName: string, eventCategory: string, eventLabel: any = null, eventValue: any = null) {
    // gtag("event", eventName, {
    //   eventCategory,
    //   eventLabel,
    //   eventValue,
    // });
  }
}
