import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as fhir from "fhir-stu3";
import { PatientDTO } from "../models/patient.model";

@Injectable({
  providedIn: "root"
})
export class DoctorService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllCriticalEvents() {
    return this.httpClient.get<Array<any>>(`${environment.serverPath}/api/doctors/critical-notifications`);
  }

  public getPatientsWithConsent() {
    return this.httpClient.get<Array<fhir.Patient>>(`${environment.serverPath}/api/doctors/patients-with-consent`);
  }

  public getDemoPatients() {
    return this.httpClient.get<Array<PatientDTO>>(`${environment.serverPath}/api/doctors/demo-patients`);
  }
}
