<form
  [formGroup]="form"
  (ngSubmit)="save()"
  *ngIf="fileTypes && fileTypes.length !== 0"
  style="max-height: calc(100vh - 150px); overflow-y: auto; overflow-x: hidden"
>
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.condition" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <!-- <p-dropdown
        [options]="conditions"
        formControlName="Condition"
        optionLabel="label"
        [placeholder]="'Select a medical condition' | translate"
        [showClear]="true"
      ></p-dropdown> -->
      <input pInputText formControlName="Condition" type="text" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.clinicalStatus" | translate }}:</label>
    </div>
    <div class="p-col">
      <p-dropdown
        [options]="clinicalStatusList"
        formControlName="Status"
        optionLabel="name"
        [placeholder]="'medicalRecord.clinicalStatus' | translate"
        [showClear]="true"
      ></p-dropdown>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.onSetDate" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        formControlName="From"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
        dateFormat="dd/mm/yy"
        appendTo="body"
      ></p-calendar>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.abatementDate" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        formControlName="Until"
        yearNavigator="true"
        dateFormat="dd/mm/yy"
        monthNavigator="true"
        yearRange="1910:2030"
        appendTo="body"
      ></p-calendar>
    </div>
  </div>
  <br />
  <div formArrayName="Notes" class="p-grid">
    <div class="text-icon-row">
      <label>{{ "medicalRecord.notes" | translate }}:</label>
      <div>
        <button
          *ngIf="form.get('Notes')['controls'].length > 0"
          style="margin-right: 5px; background-color: red"
          pButton
          [icon]="'fas fa-minus'"
          (click)="onRemoveElement('Notes')"
          iconPos="left"
          type="button"
        ></button>
        <button pButton [icon]="'fas fa-plus'" (click)="onAddElement('Notes')" iconPos="left" type="button"></button>
      </div>
    </div>
    <div *ngFor="let notesControl of form.get('Notes')['controls']; let i = index" class="add-text-field">
      <input pInputText [formControlName]="i" type="text" />
    </div>
  </div>
  <br />
  <div class="d-flex justify-content-between" *ngIf="model && model.extension && model.extension.length > 0">
    <label class="item-label">{{ "activityItem.Attachments" | translate }}:</label>
    <div *ngFor="let attachment of model.extension; let idx = index">
      <a class="item-content mr-1" [href]="serverPath + attachment.valueString" target="_blank">
        {{ idx + 1 < model.extension.length ? idx + 1 + ", " : idx + 1 }}
      </a>
      <button
        pButton
        type="button"
        (click)="removeAttachment(attachment, idx)"
        icon="pi pi-times"
        class="p-button-danger p-button-outlined"
        style="border: none"
      ></button>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="!model || !model.extension || model.extension.length === 0">
    <div class="col-md-12">
      <label style="font-size: 1rem" class="d-block">{{ "medicalRecord.IncludeAttachment" | translate }}:</label>
      <!-- <label class="d-block">{{ "medicalRecord.SelectFileType" | translate }}</label> -->
      <p-dropdown
        [options]="fileTypes"
        formControlName="FileType"
        optionLabel="name"
        dataKey="id"
        [placeholder]="'medicalRecord.SelectFileType' | translate"
        [showClear]="true"
      ></p-dropdown>
    </div>
  </div>
  <div
    class="row mt-3"
    *ngIf="(!model || !model.extension || model.extension.length === 0) && selectedFileTypeId !== undefined"
  >
    <div class="col-md-12">
      <label class="d-block">{{ "educationPage.popup.labels.File" | translate }}</label>
      <p-fileUpload
        *ngIf="selectedFileTypeId === 0"
        name="myPicture"
        auto="auto"
        customUpload="true"
        (uploadHandler)="uploadFile($event)"
        accept="image/*"
      ></p-fileUpload>
      <p-fileUpload
        *ngIf="selectedFileTypeId === 1"
        name="myPdf"
        auto="auto"
        customUpload="true"
        (uploadHandler)="uploadFile($event)"
        accept="application/pdf"
      ></p-fileUpload>
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      [disabled]="isFormSubmitting"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <!-- <button
      (click)="onClose()"
      [disabled]="isFormSubmitting"
      class="p-button-rounded p-button-danger"
      icon="fas fa-times"
      [label]="'general.button.texts.Cancel' | translate"
      type="button"
      pButton
    ></button> -->
  </div>
</form>
