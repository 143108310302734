<form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.status" | translate }}:</label>
    </div>
    <div class="p-col">
      <p-dropdown
        [options]="statusList"
        formControlName="Status"
        optionLabel="name"
        [placeholder]="'medicalRecord.status' | translate"
        [showClear]="true"
      ></p-dropdown>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.procedure" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Procedure" type="text" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.Category" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Category" type="text" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.datePerformed" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar
        formControlName="DatePerformed"
        yearNavigator="true"
        monthNavigator="true"
        yearRange="1910:2030"
      >
      </p-calendar>
    </div>
  </div>
  <br />
  <div formArrayName="Reason" class="p-grid">
    <div class="text-icon-row">
      <label>{{ "medicalRecord.reason" | translate }}:</label>
      <div>
        <button
          *ngIf="form.get('Reason')['controls'].length > 0"
          style="margin-right: 5px; background-color: red"
          pButton
          [icon]="'fas fa-minus'"
          (click)="onRemoveElement('Reason')"
          iconPos="left"
          type="button"
        ></button>
        <button pButton [icon]="'fas fa-plus'" (click)="onAddElement('Reason')" iconPos="left" type="button"></button>
      </div>
    </div>
    <div *ngFor="let reasonControl of form.get('Reason')['controls']; let i = index" class="add-text-field">
      <input pInputText [formControlName]="i" type="text" />
    </div>
  </div>
  <br />
  <div formArrayName="Notes" class="p-grid">
    <div class="text-icon-row">
      <label>{{ "medicalRecord.notes" | translate }}:</label>
      <div>
        <button
          *ngIf="form.get('Notes')['controls'].length > 0"
          style="margin-right: 5px; background-color: red"
          pButton
          [icon]="'fas fa-minus'"
          (click)="onRemoveElement('Notes')"
          iconPos="left"
          type="button"
        ></button>
        <button pButton [icon]="'fas fa-plus'" (click)="onAddElement('Notes')" iconPos="left" type="button"></button>
      </div>
    </div>
    <div *ngFor="let notesControl of form.get('Notes')['controls']; let i = index" class="add-text-field">
      <input pInputText [formControlName]="i" type="text" />
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <!-- <button (click)="onClose()" class="p-button-rounded p-button-danger" icon="fas fa-times"
      [label]="'general.button.texts.Cancel' | translate" type="button" pButton></button> -->
  </div>
</form>
