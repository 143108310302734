<div>
    <form [formGroup]="form" novalidate (submit)="save()">
        <div>
            <label>{{'Enter the email of the caregiver'|translate}}</label>
            <input pInputText formControlName="email">
        </div>
        <div class="p-1"></div>
        <div class="separator"></div>
        <div class="p-1"></div>
        <div class="d-flex">
            <div class="flex-grow-1"></div>
            <button pButton type="submit">{{'Send invitation'|translate}}</button>
        </div>
    </form>
</div>