import { Injectable, EventEmitter } from "@angular/core";
import { LoggerService } from "./core/logger.service";
import { Helpers } from "./services/app.helpers";
import { AuthenticationService } from "./services/authentication.service";
import { SocketEvents } from "./services/app.constants";
import { ChatService } from "./pages/chat/chat.service";
import { MessageBroadcasterService } from "./services/message.service";
import { NotificationsService } from "./services/notifications.service";
import { Socket } from "ngx-socket-io";

export interface ITopicMessage {
  topic: string;
  message: any;
}

@Injectable({
  providedIn: "root",
})
export class SocketsService {
  onTopicRecieved: EventEmitter<ITopicMessage> = new EventEmitter<ITopicMessage>();

  private messageQueue: Array<any> = [];
  private storageMessageQueueName = "soc_mes";

  constructor(
    private logger: LoggerService,
    private helpers: Helpers,
    private authService: AuthenticationService,
    private chatService: ChatService,
    private messageService: MessageBroadcasterService,
    private notificationsService: NotificationsService,
    private socket: Socket
  ) {
    if (
      localStorage.getItem(this.storageMessageQueueName) !== undefined &&
      localStorage.getItem(this.storageMessageQueueName) !== null
    ) {
      this.messageQueue = JSON.parse(localStorage.getItem(this.storageMessageQueueName)!);
    }
  }

  initSocketSubscriptions() {
    this.socket.on("connect", () => {
      setTimeout(() => {
        this.authenticateSocketIo();
        this.logger.log("Socket io connected");

        this.messageQueue.forEach((x) => {
          this.sendMessage(x);
        });
      }, 1000);
    });

    this.socket.fromEvent<any>(SocketEvents.NewChatMessage).subscribe((data) => {
      this.chatService.newChatMessageNotification.emit(data);
      this.chatService.checkIfUserHasUnread();
    });
    this.socket.fromEvent<any>(SocketEvents.PatientCriticalEvent).subscribe((data) => {
      this.messageService.patientCriticalEvent.emit(data);
    });
    this.socket.fromEvent<any>(SocketEvents.ConsentAccepted).subscribe((data) => {
      this.messageService.patientConsentAccepted.emit();
    });
    this.socket.fromEvent<any>(SocketEvents.Consent).subscribe((data) => {
      this.messageService.patientConsentEvent.emit();
    });
    this.socket.fromEvent<any>(SocketEvents.NotificationsChanged).subscribe((data) => {
      this.notificationsService.notificationsChanged.next(true);
    });
    this.socket.fromEvent<any>(SocketEvents.AppointmentsUpdated).subscribe((data) => {
      this.messageService.appointmentsUpdated.emit();
      this.notificationsService.notificationsChanged.next(true);
    });
    this.socket.fromEvent<any>(SocketEvents.AppointmentAccepted).subscribe((data) => {
      this.messageService.appointmentAccepted.emit(data);
    });
    this.socket.on(SocketEvents.Delivered, (data: any) => {
      this.setMessageDelivered(data.id);
    });
  }

  authenticateSocketIo() {
    if (!this.authService.getJwtToken()) return;

    this.socket.emit(SocketEvents.Authorize, { token: this.authService.getJwtToken() });
  }

  logoutSockets(token: any) {
    this.socket.emit(SocketEvents.LogoutSockets, { token });
  }

  sendMessage(data: { message: any; type: string; id?: string }) {
    try {
      data.id = this.helpers.newGuid();
      const request = JSON.stringify(data);
      this.addMessageToQueue(data);

      if (this.socket.ioSocket.connected) {
        return;
      }

      this.socket.emit(data.type, { message: data.message, id: data.id });
    } catch (err) {
      this.logger.log(err);
    }
  }

  private setMessageDelivered(messageId: string) {
    this.removeMessageFromQueue(messageId);
  }

  private addMessageToQueue(message: any) {
    if (!this.messageQueue.find((x) => x.id === message.id)) {
      this.messageQueue.push(message);
    }
    this.updateLocalStorage();
  }

  private removeMessageFromQueue(messageId: string) {
    this.messageQueue.splice(
      this.messageQueue.findIndex((x: { id: string }) => x.id === messageId),
      1
    );
    this.updateLocalStorage();
  }

  private updateLocalStorage() {
    localStorage.setItem(this.storageMessageQueueName, JSON.stringify(this.messageQueue));
  }
}
