import { Injectable, EventEmitter } from "@angular/core";
import { PatientDTO } from "src/app/models/patient.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  IPatientObservationDTO,
  IPatientMedicationAdherence,
  IPatientExerciseAdherence,
  IPatientObservationAdherence,
  IPatientMedicineAdherencePerDay,
} from "./patient-dashboard/patient-record.model";
import { map } from "rxjs/operators";
import * as moment from "moment";
import * as fhir from "fhir-stu3";

@Injectable({
  providedIn: "root",
})
export class PatientRecordService {
  private _patientInfo!: PatientDTO;
  private _wizardCompleted!: boolean;

  public patientIdChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private httpClient: HttpClient) {}

  get patientId() {
    if (!this._patientInfo) return undefined;
    return this._patientInfo.Id;
  }

  get patientInfo() {
    return this._patientInfo;
  }
  set patientInfo(value: PatientDTO) {
    this._patientInfo = value;
    if (!this._patientInfo.Age && this._patientInfo.DateOfBirth) {
      this._patientInfo.Age = moment()
        .month(0)
        .diff(moment(this._patientInfo.DateOfBirth, "YYYY-MM-DD").month(0), "years")
        .toString();
    }
  }

  get wizardCompleted() {
    return this._wizardCompleted;
  }

  set wizardCompleted(value: boolean) {
    this._wizardCompleted = value;
  }

  get patientFullName() {
    if (!this._patientInfo) return "";

    return this._patientInfo.Fullname;
  }

  public getPatientLastObservations() {
    return this.httpClient.get<
      Array<{ Id: string; ObservationName: string; Value: { component: fhir.ObservationComponent; date: string } }>
    >(`${environment.serverPath}/api/patient/last-observations/${this.patientId}`);
  }

  public deleteLastObservation(obsId: string) {
    return this.httpClient.delete<boolean>(`${environment.serverPath}/api/patient/last-observations/${obsId}`);
  }

  public getMetabolicBodyAnalysisObservations() {
    return this.httpClient.get<
      Array<{ ObservationName: string; Value: { component: fhir.ObservationComponent; date: string } }>
    >(`${environment.serverPath}/api/patient/metabolic/body-analysis/${this.patientId}`);
  }

  public getPatientHistory() {
    return this.httpClient.get<any>(`${environment.serverPath}/api/patient/history/${this.patientId}`);
  }

  public getPatientProcedures() {
    return this.httpClient
      .get<Array<fhir.Procedure>>(`${environment.serverPath}/api/patient-record/procedures/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];
          return x.sort((a, b) => moment(b.performedDateTime).unix() - moment(a.performedDateTime).unix());
        })
      );
  }

  public addPatientProcedure(procedure: any) {
    procedure["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/procedures/${this.patientId}`, procedure);
  }

  public getPatientVaccines() {
    return this.httpClient
      .get<Array<fhir.Immunization>>(`${environment.serverPath}/api/patient-record/vaccines/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];
          return x.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
        })
      );
  }

  public addPatientVaccine(vaccine: any) {
    vaccine["patient"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/vaccines/${this.patientId}`, vaccine);
  }

  public getPatientFamilyMembers() {
    return this.httpClient
      .get<Array<fhir.FamilyMemberHistory>>(
        `${environment.serverPath}/api/patient-record/family-history/${this.patientId}`
      )
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];
          return x.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
        })
      );
  }

  public addPatientFamilyMember(member: any) {
    member["patient"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(
      `${environment.serverPath}/api/patient-record/family-history/${this.patientId}`,
      member
    );
  }

  public getPatientAllergies() {
    return this.httpClient
      .get<Array<fhir.AllergyIntolerance>>(`${environment.serverPath}/api/patient-record/allergies/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x
            .map((x) => ({ ...x, meta: { lastUpdated: moment(x.meta?.lastUpdated).format("YYYY-MM-DD") } }))
            .sort((a, b) => moment(b.meta.lastUpdated).unix() - moment(a.meta.lastUpdated).unix());
        })
      );
  }

  public addPatientCondition(condition: any) {
    condition["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/conditions/${this.patientId}`, condition);
  }

  public getPatientConditions() {
    return this.httpClient
      .get<Array<fhir.Condition>>(`${environment.serverPath}/api/patient-record/conditions/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x
            .map((x) => ({ ...x, meta: { lastUpdated: moment(x.meta?.lastUpdated).format("YYYY-MM-DD") } }))
            .sort((a, b) => moment(b.meta.lastUpdated).unix() - moment(a.meta.lastUpdated).unix());
        })
      );
  }

  public addPatientReport(report: any) {
    report["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/reports/${this.patientId}`, report);
  }

  public getPatientReports() {
    return this.httpClient
      .get<Array<fhir.DiagnosticReport>>(`${environment.serverPath}/api/patient-record/reports/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x
            .map((x) => ({ ...x, meta: { lastUpdated: moment(x.meta?.lastUpdated).format("YYYY-MM-DD") } }))
            .sort((a, b) => moment(b.meta.lastUpdated).unix() - moment(a.meta.lastUpdated).unix());
        })
      );
  }

  public addPatientAllergy(allergy: any) {
    allergy["patient"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/allergies/${this.patientId}`, allergy);
  }

  public getPatientMedication() {
    return this.httpClient
      .get<Array<fhir.MedicationRequest>>(`${environment.serverPath}/api/patient-record/medication/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x.sort(
            (a, b) =>
              moment(b.dosageInstruction?.[0]?.timing?.repeat?.boundsPeriod?.start).unix() -
              moment(a.dosageInstruction?.[0]?.timing?.repeat?.boundsPeriod?.start).unix()
          );
        })
      );
  }

  public addPatientMedication(medication: any) {
    medication["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(
      `${environment.serverPath}/api/patient-record/medication/${this.patientId}`,
      medication
    );
  }

  public getPatientSocialHistory() {
    return this.httpClient
      .get<Array<fhir.Observation>>(`${environment.serverPath}/api/patient-record/social-history/${this.patientId}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x.sort((a, b) => moment(b.effectiveDateTime).unix() - moment(a.effectiveDateTime).unix());
        })
      );
  }

  public addPatientSocialHistory(socialHistory: any) {
    socialHistory["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(
      `${environment.serverPath}/api/patient-record/social-history/${this.patientId}`,
      socialHistory
    );
  }

  public addEncounter(resource: any) {
    resource["subject"] = { reference: `Patient/${this.patientId}` };
    return this.httpClient.post(`${environment.serverPath}/api/patient-record/encounters/${this.patientId}`, resource);
  }

  public getEncounters(id?: string) {
    if (!id) {
      id = this.patientId;
    }
    return this.httpClient
      .get<Array<fhir.Encounter>>(`${environment.serverPath}/api/patient-record/encounters/${id}`)
      .pipe(
        map((x) => {
          if (!x || !Array.isArray(x)) return [];

          return x
            .map((x) => ({ ...x, meta: { lastUpdated: moment(x.meta?.lastUpdated).format("YYYY-MM-DD") } }))
            .sort((a, b) => moment(b.meta.lastUpdated).unix() - moment(a.meta.lastUpdated).unix());
        })
      );
  }

  public getPatientMedicationAdherence(patientId: string) {
    return this.httpClient.get<IPatientMedicationAdherence>(
      `${environment.serverPath}/api/adherence/medication/${patientId}`
    );
  }

  public getPatientExerciseAdherence(patientId: string) {
    return this.httpClient.get<IPatientExerciseAdherence>(
      `${environment.serverPath}/api/adherence/exercise/${patientId}`
    );
  }

  public getPatientObservationAdherence(patientId: string) {
    return this.httpClient.get<IPatientObservationAdherence>(
      `${environment.serverPath}/api/adherence/observation/${patientId}`
    );
  }

  public getPatientQuestionnaireAdherence(patientId: string) {
    return this.httpClient.get<IPatientObservationAdherence>(
      `${environment.serverPath}/api/adherence/questionnaire/${patientId}`
    );
  }

  public getDashboardMeasurementCodes(patientId: string) {
    return this.httpClient.get<Array<any>>(`${environment.serverPath}/api/page/add-dashboard-measurement/${patientId}`);
  }

  public addDashboardMeasurement(model: any) {
    return this.httpClient.post(`${environment.serverPath}/api/page/add-dashboard-measurement`, model);
  }

  public removeDashboardMeasurement(itemId: number) {
    return this.httpClient.delete(`${environment.serverPath}/api/page/add-dashboard-measurement/${itemId}`);
  }

  public updateDashboardMeasurementItemPosition(id: any, positionX: any, positionY: any) {
    return this.httpClient.put(`${environment.serverPath}/api/page/add-dashboard-measurement`, {
      id,
      positionX,
      positionY,
    });
  }

  public getPatientToDos(patientId: string) {
    const dayOfWeek = moment().format("ddd").toLowerCase();
    return this.httpClient.get<Array<any>>(
      `${environment.serverPath}/api/patient-record/careplan/to-dos/${patientId}?day=${dayOfWeek}`
    );
  }

  public deleteItem(category: string, id: string) {
    switch (category) {
      case "allergies":
        return this.httpClient.delete(`${environment.serverPath}/api/patient-record/allergies/${this.patientId}/${id}`);
      case "vaccines":
        return this.httpClient.delete(`${environment.serverPath}/api/patient-record/vaccines/${this.patientId}/${id}`);
      case "procedures":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/procedures/${this.patientId}/${id}`
        );
      case "medication":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/medication/${this.patientId}/${id}`
        );
      case "social_history":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/social-history/${this.patientId}/${id}`
        );
      case "family_history":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/family-history/${this.patientId}/${id}`
        );
      case "conditions":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/conditions/${this.patientId}/${id}`
        );
      case "reports":
        return this.httpClient.delete(`${environment.serverPath}/api/patient-record/reports/${this.patientId}/${id}`);
      case "hospitalizations":
        return this.httpClient.delete(
          `${environment.serverPath}/api/patient-record/encounters/${this.patientId}/${id}`
        );
    }
  }

  public editItem(category: string, id: string, data: any) {
    switch (category) {
      case "allergies":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/allergies/${this.patientId}/${id}`,
          data
        );
      case "vaccines":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/vaccines/${this.patientId}/${id}`,
          data
        );
      case "procedures":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/procedures/${this.patientId}/${id}`,
          data
        );
      case "medication":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/medication/${this.patientId}/${id}`,
          data
        );
      case "social_history":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/social-history/${this.patientId}/${id}`,
          data
        );
      case "family_history":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/family-history/${this.patientId}/${id}`,
          data
        );
      case "conditions":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/conditions/${this.patientId}/${id}`,
          data
        );
      case "reports":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/reports/${this.patientId}/${id}`,
          data
        );
      case "hospitalizations":
        return this.httpClient.put(
          `${environment.serverPath}/api/patient-record/encounters/${this.patientId}/${id}`,
          data
        );
    }
  }

  getMedicationAdherencePerDay(patientId: string, startDate: string, endDate: string) {
    return this.httpClient.post<Array<IPatientMedicineAdherencePerDay>>(
      `${environment.serverPath}/api/adherence/medication-per-day/${patientId}`,
      { startDate, endDate }
    );
  }

  public markWizardCompleted(completed: boolean) {
    return this.httpClient.post(`${environment.serverPath}/api/patient/mark-wizard-completed/${this.patientId}`, {
      completed,
    });
  }

  public getParticipationInfo() {
    return this.httpClient.get(`${environment.serverPath}/api/patient/participation-dates/${this.patientId}`);
  }

  public addParticipationInfo(data: any) {
    return this.httpClient.post(`${environment.serverPath}/api/patient/participation-dates/${this.patientId}`, data);
  }

  public editParticipationInfo(data: any) {
    return this.httpClient.put(`${environment.serverPath}/api/patient/participation-dates/${this.patientId}`, data);
  }
}
