import { Component, OnInit } from "@angular/core";
import { DoctorService } from "src/app/services/doctor.service";
import { PatientsService } from "src/app/services/patients.service";
import { LoggerService } from "src/app/core/logger.service";
import * as fhir from "fhir-stu3";

@Component({
  selector: "app-compare-layout",
  templateUrl: "./compare-layout.component.html",
  styleUrls: ["./compare-layout.component.scss"],
})
export class CompareLayoutComponent implements OnInit {
  dataSource!: Array<any>;
  selectedPatients: any;

  constructor(
    private doctorService: DoctorService,
    private patientsService: PatientsService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.getPatients();
  }

  getPatients() {
    this.doctorService.getPatientsWithConsent().subscribe(
      (response: Array<fhir.Patient>) => {
        this.dataSource = [];

        response.forEach((patient) => {
          this.dataSource.push(this.patientsService.FhirPatientToPatientDTO(patient));
        });

        this.dataSource = JSON.parse(JSON.stringify(this.dataSource));
      },
      (err: any) => {
        this.logger.log(err);
      }
    );
  }

  selectedPatientsChanged(event: any) {
    this.patientsService.setSelectedPatients(event.value);
    // this.patientsService.compareSelectedPatientsChanged.emit(event.value);
  }
}
