import { Component, OnInit, Input } from "@angular/core";
import { PatientRecordService } from "../../patient-record.service";

@Component({
  selector: "app-conditions-step",
  templateUrl: "./conditions-step.component.html",
  styleUrls: ["./conditions-step.component.scss"],
})
export class ConditionsStepComponent implements OnInit {
  @Input() patientId!: string;

  constructor(private patientRecordService: PatientRecordService) {}

  ngOnInit() {}
}
