import { TranslateService } from "@ngx-translate/core";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as moment from "moment";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-report-export-pdf",
  templateUrl: "./report-export-pdf.component.html",
  styleUrls: ["./report-export-pdf.component.scss"],
})
export class ReportExportPdfComponent implements OnInit {
  @ViewChild("pdf") pdf!: ElementRef;

  @Input() patientInfo: any;
  @Input() pdfModel: any;
  @Output() created = new EventEmitter<any>();

  serverPath: string = environment.serverPath;
  showCarePlan: boolean = false;

  constructor(private translationService: TranslateService) {}

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    if (!this.pdfModel) {
      setTimeout(() => {
        this.initData();
      }, 10);
      return;
    }
    if (this.pdfModel.procedures && this.pdfModel.procedures.length > 0) {
      for (const item of this.pdfModel.procedures) {
        item.categoryLabel = item.category?.text;
        item.codeLabel = item.code?.text;
        item.dateLabel = moment(item.performedDateTime, "YYYY-MM-DD").format("DD/MM/YYYY");
        item.reasonLabel = item.reasonCode?.[0]?.text ? item.reasonCode[0].text : null;
        item.noteLabel = item.note?.[0]?.text ? item.note[0].text : null;
      }
    }
    if (this.pdfModel.medication && this.pdfModel.medication.length > 0) {
      for (const item of this.pdfModel.medication) {
        item.medicationLabel = item.medicationCodeableConcept.text;
        item.periodLabel = `${moment(item.dosageInstruction[0].timing.repeat.boundsPeriod.start, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        )} - ${
          item.dosageInstruction[0].timing.repeat.boundsPeriod.end
            ? moment(item.dosageInstruction[0].timing.repeat.boundsPeriod.end, "YYYY-MM-DD").format("DD/MM/YYYY")
            : this.translationService.instant("exportPdfPage.ongoing")
        }`;
      }
    }
    if (this.pdfModel.carePlan && this.pdfModel.carePlan.length > 0) {
      for (const type of this.pdfModel.carePlan) {
        type.showCategory = false;
        if (type.values && type.values.length > 0) {
          type.categoryLabel = this.translationService.instant("careplan.headers." + type.type);
          for (const item of type.values) {
            switch (item.ResourceType) {
              case "Observation":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = this.translationService.instant("medicalRecord.observationCode." + item.ObservationName);
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                  item.frequencyLabel = item.Frequency;
                  item.periodUnitLabel = this.translationService.instant("activityItem.periodUnits." + item.PeriodUnit);
                  if (item.TimeOfDay && item.TimeOfDay.length && item.TimeOfDay.length > 0) {
                    item.timeOfDayLabel = item.TimeOfDay.join(", ");
                  }
                }
                break;
              case "MedicationRequest":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  const daysOfWeek: any[] = [];
                  if (item.DaysOfWeek && item.DaysOfWeek.length > 0) {
                    item.DaysOfWeek.forEach((z: string) => {
                      daysOfWeek.push(this.translationService.instant("exportPdfPage." + z));
                    });
                  }
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.DrugName;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                  item.frequencyLabel = item.Frequency;
                  item.periodUnitLabel = this.translationService.instant("activityItem.periodUnits." + item.PeriodUnit);
                  item.doseLabel = `${item.DoseValue} ${item.DoseUnit}`;
                  item.daysOfWeekLabel = daysOfWeek.length > 0 ? daysOfWeek.join(", ") : null;
                  if (item.TimeOfDay && item.TimeOfDay.length && item.TimeOfDay.length > 0)
                    item.timeOfDayLabel = item.TimeOfDay.join(", ");
                }
                break;
              case "NutritionOrder":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.Nutrition?.plan?.nutritionPlan?.Name;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                }
                break;
              case "Education":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.EducationalMaterial?.Name;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                  if (
                    item.EducationalMaterial?.EducationType !== null &&
                    item.EducationalMaterial?.EducationType !== undefined
                  ) {
                    item.typeLabel = `${this.translationService.instant(
                      "educationTypes." + item.EducationalMaterial?.EducationType
                    )}`;
                  }

                  item.PathLabel = item.EducationalMaterial?.Path;
                }
                break;
              case "Laboratory":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.LaboratoryTestName;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")}`;
                }
                break;
              case "Exercise":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.ExerciseType;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                  item.frequencyLabel = item.Frequency;
                  item.periodUnitLabel = this.translationService.instant("activityItem.periodUnits." + item.PeriodUnit);
                }
                break;
              case "Questionnaire":
                if (moment(item.EndDate).isSameOrAfter(moment(), "day")) {
                  this.showCarePlan = true;
                  type.showCategory = true;
                  item.show = item.Status === "finished" ? false : true;
                  item.label = item.Data?.find(
                    (z: any) => z.locale === this.translationService.currentLang
                  )?.questionnaire?.Name;
                  item.rangeLabel = `${moment(item.StartDate).format("DD/MM/YYYY")} - ${moment(item.EndDate).format(
                    "DD/MM/YYYY"
                  )}`;
                }
                break;
            }
          }
        }
      }
    }

    this.exportToPdf();
  }

  exportToPdf() {
    if (this.patientInfo && this.patientInfo.DateOfBirth) {
      this.patientInfo.DateOfBirthLabel = moment(this.patientInfo.DateOfBirth, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
    if (!this.pdf) {
      setTimeout(() => {
        this.exportToPdf();
      }, 10);
      return;
    }
    const doc = new jsPDF();

    const content = this.pdf.nativeElement;
    const sections = content.querySelectorAll(".summary-info-section");
    let currentPageHeight = 10;
    let currentSectionIndex = 0;

    const addSectionToPdf = async (sectionIndex: any) => {
      if (sectionIndex >= sections.length) {
        doc.save(`${this.translationService.instant("patientReport")}.pdf`);
        this.created.emit();
        return;
      }

      const section = sections[sectionIndex];

      // Use html2canvas to capture the section content
      const canvas = await html2canvas(section, { scrollY: -window.scrollY });
      const imgData = canvas.toDataURL("image/png");

      const sectionHeight = canvas.height * (210 / canvas.width); // Adjust height based on A4 width (210mm)

      // Check if adding this section will exceed the page height
      if (currentPageHeight + sectionHeight > doc.internal.pageSize.height) {
        doc.addPage();
        currentPageHeight = 10;
      }

      // Add the section content to the PDF
      doc.addImage(imgData, "PNG", 10, currentPageHeight, doc.internal.pageSize.getWidth() - 20, sectionHeight);
      currentPageHeight += sectionHeight;

      // Move to the next section
      addSectionToPdf(sectionIndex + 1);
    };

    addSectionToPdf(currentSectionIndex);
  }
}
