import { Subscription } from "rxjs";
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ThemeService } from "../theme.service";
import { trigger, state, style, transition, animate } from "@angular/animations";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "iti-shell-layout",
  styleUrls: ["./shell-layout.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          "padding-left": "250px",
        })
      ),
      state(
        "out",
        style({
          "padding-left": "0px",
        })
      ),
      transition("in => out", [animate("200ms ease-in")]),
      transition("out => in", [animate("200ms ease-in")]),
    ]),
  ],
  template: `
    <div style="height: 100vh;" class="d-flex flex-column">
      <iti-header></iti-header>
      <iti-sidebar *ngIf="!isMobile"></iti-sidebar>
      <app-mobile-sidebar *ngIf="isMobile"></app-mobile-sidebar>
      <div id="main-content" [@slideInOut]="isOpen">
        <div>
          <!-- <app-breadcrumb></app-breadcrumb> -->
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
})
export class ShellLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  isMobile!: boolean;
  isMobileChangedSubscription!: Subscription;

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef) {}

  get isOpen() {
    return this.themeService.animationState;
  }

  ngOnDestroy(): void {
    this.isMobileChangedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.isMobile = this.themeService.isMobile;
    this.isMobileChangedSubscription = this.themeService.notifyIsMobileChanged().subscribe(() => {
      this.isMobile = this.themeService.isMobile;
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth < 768) {
      this.themeService.closeMenu();
      this.themeService.setMobile(true);
    } else {
      this.themeService.openMenu();
      this.themeService.setMobile(false);
    }
  }
}
