import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";

@Component({
  selector: "app-add-allergy",
  templateUrl: "./add-allergy.component.html",
  styleUrls: ["./add-allergy.component.scss"],
})
export class AddAllergyComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  criticalityList = [
    { id: 0, name: this.translationService.instant("PatientRecord.low"), value: "low" },
    { id: 1, name: this.translationService.instant("PatientRecord.high"), value: "high" },
    { id: 2, name: this.translationService.instant("PatientRecord.unable-to-assess"), value: "unable-to-assess" },
  ];

  categoryList = [
    { id: 0, name: this.translationService.instant("PatientRecord.food"), value: "food" },
    { id: 1, name: this.translationService.instant("PatientRecord.medication"), value: "medication" },
    { id: 1, name: this.translationService.instant("PatientRecord.environment"), value: "environment" },
    { id: 1, name: this.translationService.instant("PatientRecord.biologic"), value: "biologic" },
  ];

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Allergy: [(this.model && this.model.code.text) || "", Validators.required],
      Category: [
        (this.model && this.categoryList.find((x) => x.name === this.model.category[0])) || "",
        Validators.required,
      ],
      Criticality: [
        (this.model && this.criticalityList.find((x) => x.name === this.model.criticality)) || "",
        Validators.required,
      ],
      Notes: this.fb.array([]),
    });

    if (this.model && this.model.note && this.model.note.length !== 0) {
      this.model.note.forEach((note: any) => {
        (this.form.controls["Notes"] as FormArray).push(new FormControl(note.text, Validators.required));
      });
    }
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    // this.form.reset();
    // this.form.setControl("Notes", this.fb.array([]));
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let allergyModel: any = {};

    allergyModel["resourceType"] = "AllergyIntolerance";
    allergyModel["clinicalStatus"] = "active";
    allergyModel["verificationStatus"] = "confirmed";
    allergyModel["code"] = { text: this.form.controls["Allergy"].value };
    allergyModel["category"] = [this.form.controls["Category"].value.value];
    allergyModel["criticality"] = this.form.controls["Criticality"].value.value;
    if (this.form.controls["Notes"].value.length !== 0) {
      allergyModel["note"] = this.form.controls["Notes"].value.map((x: any) => ({
        text: x,
      }));
    }

    if (this.model) {
      delete this.model.meta;
      allergyModel = { ...this.model, ...allergyModel };
    }

    this.success.emit(allergyModel);
    this.form.reset();
    this.closed.emit();
  }
}
