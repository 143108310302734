import { Component, HostListener, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ThemeService } from "../theme.service";
import { menuItem } from "src/app/services/app.constants";
import { menuItems } from "src/app/services/app.menu";
import { Router } from "@angular/router";

@Component({
  selector: "app-mobile-sidebar",
  templateUrl: "./mobile-sidebar.component.html",
  styleUrls: ["./mobile-sidebar.component.scss"],
})
export class MobileSidebarComponent implements OnInit {
  menu: Array<menuItem> = menuItems.filter((x) => x.sidebarMenuItem);

  constructor(private themeService: ThemeService, private authService: AuthenticationService, private router: Router) {}

  get display() {
    return this.themeService.displaySideOnMobile;
  }
  set display(value) {
    this.themeService.displaySideOnMobile = value;
  }

  ngOnInit() {
    this.themeService.closeMenu();
    const userRoles = this.authService.getUserRoles();
    this.menu = this.menu.filter((menuItem) => {
      if (menuItem.roles && !menuItem.roles.some((role) => userRoles.includes(role))) {
        return false;
      }
      return true;
    });

    this.themeService.closeMenu();
  }

  toggleMenu(): void {
    this.themeService.toggleMenu();
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }
}
