import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { INotificationResponse } from "../models/notification.model";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  notificationsChanged = new Subject<boolean>();

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {}

  getAllNotifications(
    isRead?: boolean,
    startDate?: number,
    type?: string,
    patientId?: number,
    limit?: number,
    page?: number,
    isFixed?: boolean
  ) {
    return this.httpClient
      .get<INotificationResponse>(
        patientId
          ? `${environment.serverPath}/api/notifications/${patientId}`
          : `${environment.serverPath}/api/notifications`,
        {
          params: new HttpParams()
            .set("read", isRead === undefined ? "" : isRead.toString())
            .set("isFixed", isFixed === undefined ? "" : isFixed.toString())
            .set("start", startDate === undefined ? "0" : startDate.toString())
            .set("type", type === undefined ? "" : type)
            .set("limit", limit === undefined ? "20" : limit.toString())
            .set("page", page === undefined ? "0" : page.toString()),
        }
      )
      .pipe(
        map((x: INotificationResponse) => {
          if (x.notifications && Array.isArray(x.notifications)) {
            const result = x.notifications.map((message) => {
              if (this.translateService.instant(message.Message).indexOf("{{") > -1) {
                let str = this.translateService.instant(message.Message);
                for (const prop in message.data) {
                  if (prop) {
                    str = str.replace(
                      new RegExp(`{{${prop}:trans}}`, "g"),
                      this.translateService.instant("notificationsPage." + message.data[prop])
                    );
                    str = str.replace(new RegExp(`{{${prop}}}`, "g"), message.data[prop]);
                  }
                }
                return { ...message, Message: str };
              }
              return message;
            });
            return { ...x, notifications: result };
          } else {
            return [];
          }
        })
      );
  }

  markNotificationAsRead(postData: { id: number; isCritical: boolean; isRead: boolean }) {
    return this.httpClient.post<boolean>(`${environment.serverPath}/api/notifications/read`, postData);
  }

  markNotificationAsFixed(postData: { id: number; isCritical: boolean; isFixed: boolean }) {
    return this.httpClient.post<boolean>(`${environment.serverPath}/api/notifications/fixed`, postData);
  }

  markAllNotificationsAsRead(isCritical: boolean = false) {
    return this.httpClient.post<boolean>(`${environment.serverPath}/api/notifications/mark-all-as-read`, {
      isCritical: isCritical,
    });
  }

  markAllNotificationsAsFixed() {
    return this.httpClient.post<boolean>(`${environment.serverPath}/api/notifications/mark-all-as-fixed`, {});
  }

  deleteNotification(id: number, type: string) {
    return this.httpClient.delete<boolean>(`${environment.serverPath}/api/notifications/${id}`, {
      params: new HttpParams().set("type", type),
    });
  }

  notifyNotificationsChanged() {
    return this.notificationsChanged.asObservable();
  }
}
