import { NgModule } from "@angular/core";
import { DefaultModules } from "src/app/services/app.constants";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InviteCaregiverFormComponent } from "./invite-caregiver-form.component";

@NgModule({
  declarations: [
    InviteCaregiverFormComponent,
  ],
  imports: [
    ...DefaultModules,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule
  ],
  exports: [InviteCaregiverFormComponent]
})
export class InviteCaregiverFormModule {}