import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";

@Component({
  selector: "app-add-medication",
  templateUrl: "./add-medication.component.html",
  styleUrls: ["./add-medication.component.scss"],
})
export class AddMedicationComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Medication: [(this.model && this.model.medicationCodeableConcept.text) || "", Validators.required],
      From: [
        (this.model &&
          this.model.dosageInstruction &&
          this.model.dosageInstruction[0].timing.repeat.boundsPeriod.start &&
          moment(this.model.dosageInstruction[0].timing.repeat.boundsPeriod.start).toDate()) ||
          "",
        Validators.required,
      ],
      Until: [
        (this.model &&
          this.model.dosageInstruction &&
          this.model.dosageInstruction[0].timing.repeat.boundsPeriod.end &&
          moment(this.model.dosageInstruction[0].timing.repeat.boundsPeriod.end).toDate()) ||
          "",
      ],
    });
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    // this.form.reset();
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let memberModel: any = {};

    memberModel["resourceType"] = "MedicationRequest";
    memberModel["status"] = "active";
    memberModel["intent"] = "order";
    memberModel["identifier"] = { type: { text: "order" } };
    memberModel["medicationCodeableConcept"] = {
      text: this.form.controls["Medication"].value,
    };
    if (this.form.controls["Until"].value) {
      memberModel["dosageInstruction"] = {
        timing: {
          repeat: {
            boundsPeriod: {
              start: moment(this.form.controls["From"].value).format("YYYY-MM-DD"),
              end: moment(this.form.controls["Until"].value).format("YYYY-MM-DD"),
            },
          },
        },
      };
    } else {
      memberModel["dosageInstruction"] = {
        timing: {
          repeat: {
            boundsPeriod: {
              start: moment(this.form.controls["From"].value).format("YYYY-MM-DD"),
            },
          },
        },
      };
    }

    if (this.model) {
      delete this.model.meta;
      memberModel = { ...this.model, ...memberModel };
    }

    this.success.emit(memberModel);
    this.form.reset();
    this.closed.emit();
  }
}
