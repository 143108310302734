import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
  })
export class LoggerService {
    private method = console.log;

    public log(message: any) {
        if (!environment.production) {
            this.method(message);
        }
    }
}