import { NgModule } from "@angular/core";
import { ChatbotUiComponent } from "./chatbot-ui.component";
import { FormsModule } from "@angular/forms";
import { DefaultModules } from "../../../services/app.constants";
import { DraggableModule } from "../draggable/draggable.module";

@NgModule({
  declarations: [ChatbotUiComponent],
  imports: [...DefaultModules, FormsModule, DraggableModule],
  exports: [ChatbotUiComponent],
})
export class ChatbotUiModule {}
