import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-info-popup",
  templateUrl: "./info-popup.component.html",
  styleUrls: ["./info-popup.component.scss"],
})
export class InfoPopupComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Input() attributes: Array<{ id: string; text: string }> = [];
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  serverPath = environment.serverPath;

  finishedTranslations = false;

  get encountersCompletedObject() {
    const parsed = JSON.parse(this.model?.extension?.find((x: any) => x.url === "completed").valueString);
    const result = [];
    for (const item in parsed) {
      const translations = this.getTranslationsEncountersCompleted(item);
      if (translations) {
        result.push({
          tr: translations.tr,
          unit: translations.unit,
          value: parsed[item],
        });
      }
    }
    return result;
  }

  constructor(private translationService: TranslateService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.finishedTranslations = false;
    this.translate(changes["model"].currentValue);
    // changes.attributes.currentValue.forEach((attr, idx) => {
    //   if (!changes.model.currentValue[attr.id]) {
    //     delete changes.attributes.currentValue[idx];
    //   }
    // });
  }

  translate(model: any) {
    if (model.resourceType === "AllergyIntolerance") {
      model.category = model.category.map((x: any) => this.translationService.instant(`PatientRecord.${x}`));
      model.criticality = this.translationService.instant(`PatientRecord.${model.criticality}`);
    } else if (model.resourceType === "Procedure") {
      model.status = this.translationService.instant(`PatientRecord.${model.status}`);
    } else if (model.resourceType === "FamilyMemberHistory") {
      model.deceasedBoolean = this.translationService.instant(`PatientRecord.${model.deceasedBoolean}`);
      model.gender = this.translationService.instant(`PatientRecord.${model.gender}`);
    } else if (model.resourceType === "Condition") {
      model.clinicalStatus = this.translationService.instant(`PatientRecord.${model.clinicalStatus}`);
    }
    this.finishedTranslations = true;
  }

  getTypeOf(variable: any) {
    return typeof variable;
  }

  isArray(variable: any) {
    return variable instanceof Array;
  }

  onClose() {
    this.closed.emit();
  }

  getTranslationsEncountersCompleted(x: any) {
    enum TranslationsEnumEncounter {
      "medicationAfterDischarge" = "medicalRecord.encounter.Medication after discharge",
      "NYHAClass" = "medicalRecord.encounter.NYHA Class",
      "dryWeight" = "medicalRecord.encounter.Dry weight",
      "systolicBloodPressure" = "medicalRecord.encounter.Systolic blood pressure",
      "diastolicBloodPressure" = "medicalRecord.encounter.Diastolic blood pressure",
      "heartRate" = "medicalRecord.encounter.Heart rate",
      "haemoglobin" = "medicalRecord.encounter.Haemoglobin",
      "creatinine" = "medicalRecord.encounter.Creatinine",
      "urea" = "medicalRecord.encounter.Urea",
      "eGFR" = "medicalRecord.encounter.eGFR",
      "followUp" = "medicalRecord.encounter.Advice for follow-ups",
      "notes" = "medicalRecord.encounter.Further notes",
      "poc" = "medicalRecord.encounter.Point of contact",
    }

    enum UnitsEnumEncounter {
      "medicationAfterDischarge" = "",
      "NYHAClass" = "",
      "dryWeight" = "Kgr",
      "systolicBloodPressure" = "mmHg",
      "diastolicBloodPressure" = "mmHg",
      "heartRate" = "bpm",
      "haemoglobin" = "g/dL",
      "creatinine" = "mg/dL",
      "urea" = "mg/dL",
      "eGFR" = "mL/min/1.73m2",
      "followUp" = "",
      "notes" = "",
      "poc" = "",
    }

    if (TranslationsEnumEncounter[x as keyof typeof TranslationsEnumEncounter]) {
      return {
        tr: this.translationService.instant(TranslationsEnumEncounter[x as keyof typeof TranslationsEnumEncounter]),
        unit: UnitsEnumEncounter[x as keyof typeof UnitsEnumEncounter],
      };
    } else return null;
  }
}
