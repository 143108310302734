import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/language.service";

@Component({
  selector: "app-iti-empty-layout",
  styleUrls: ["./empty-layout.component.scss"],
  templateUrl: "./empty-layout.component.html",
})
export class EmptyLayoutComponent implements OnInit {
  constructor(private translateService: TranslateService, private languageService: LanguageService) {}

  ngOnInit() {}

  changeLanguage(lang: string) {
    this.languageService.changeLanguage(lang);
  }

  getLanguageImage(lang: string = "") {
    if (lang === "") {
      lang = this.translateService.currentLang;
    }

    let position = "0";
    switch (lang) {
      case "en":
        position = "-2182px";
        break;
      case "el":
        position = "-2446px";
        break;
    }

    return position;
  }
}
